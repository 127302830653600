import React from 'react';
import { Link } from 'react-router-dom';

import { Indicateur } from '../../types/Indicateur';

import API_URL from '../../constants/config';

export type Props = {
  indicateur: Indicateur;
};

const CardIndicateur: React.FC<Props> = ({ indicateur }) => {
  return (
    <Link className='group' to={`/indicateur/${indicateur.indicateurId}`}>
      <div className='relative pt-[50%] sm:pt-[70%] rounded-xl overflow-hidden'>
        <img
          className='size-full absolute top-0 start-0 object-cover group-hover:scale-105 transition-transform duration-500 ease-in-out rounded-xl'
          src={`${API_URL}/uploads/${indicateur.image || ''}`}
          alt={`Indicateur crypto trading view ${indicateur.name || ''}`}
        />
      </div>
      <div className='mt-7'>
        <h3 className='text-xl font-semibold text-gray-800 group-hover:text-gray-600 dark:text-neutral-300 dark:group-hover:text-white'>
          {indicateur.name || ''}
        </h3>
        <p className='mt-3 text-gray-800 dark:text-neutral-200'>
          {indicateur.smalldescription || ''}
        </p>
        <p className='mt-5 inline-flex items-center gap-x-1 text-blue-600 decoration-2 group-hover:underline font-medium'>
          Voir
          <svg
            className='flex-shrink-0 size-4'
            xmlns='http://www.w3.org/2000/svg'
            width={24}
            height={24}
            viewBox='0 0 24 24'
            fill='none'
            stroke='currentColor'
            strokeWidth={2}
            strokeLinecap='round'
            strokeLinejoin='round'
          >
            <path d='m9 18 6-6-6-6' />
          </svg>
        </p>
      </div>
    </Link>
  );
};

export default CardIndicateur;
