import { useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { CreateUpdateIndicateurProps, Indicateur } from '../../types/Indicateur';
import { ApiError } from '../../types/Api';

import API_URL from '../../constants/config';

import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import { deleteFile, uploadFile } from '../../lib/utils';

import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store/configureStore';
import {
  createIndicateur,
  deleteIndicateur,
  updateIndicateur,
} from '../../store/modules/indicateurs';

import { ClipLoader } from 'react-spinners';
import { ChevronLeft, AlertCircle, Upload } from 'lucide-react';

import { Button } from '../../components/ui/button';
import EditorTinymce from '../../components/EditorTinymce';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '../../components/ui/card';

import { Trash } from 'lucide-react';
import { Alert, AlertDescription, AlertTitle } from '../../components/ui/alert';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '../../components/ui/alert-dialog';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '../../components/ui/form';
import { Textarea } from '../../components/ui/textarea';
import { Input } from '../../components/ui/input';
import AdminLayout from '../../components/layout/AdminLayout';

const CreateUpdateIndicateurPageAD: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const fileInputRef = useRef(null);

  const { indicateurId } = useParams();
  const [loadingDelete, setLoadingDelete] = useState<boolean>(false);
  const [loadingUploadImage, setLoadingUploadImage] = useState<boolean>(false);
  const [loadingDeleteImage, setLoadingDeleteImage] = useState<boolean>(false);

  const [imageName, setImageName] = useState<string | null>(null);

  const indicateurs = useSelector((state: RootState) => state.indicateurs.data);

  let indicateur: Indicateur | undefined;
  if (indicateurId) {
    indicateur = indicateurs[indicateurId];
  }

  const FormSchema = z.object({
    name: z.string({
      required_error: 'Texte requis',
    }),
    smalldescription: z.string({
      required_error: 'Petite description requis.',
    }),
    description: z.string({
      required_error: 'Description requis.',
    }),
  });

  const form = useForm<CreateUpdateIndicateurProps>({
    resolver: zodResolver(FormSchema),

    defaultValues: indicateur && {
      ...indicateur,
    },
  });

  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const navigate = useNavigate();
  const cancel = () => {
    form.reset();
    return navigate('/admin/indicateurs');
  };

  const handleImageInputChange = (e: any) => {
    const file = e.target.files[0];

    const formData = new FormData();
    formData.append('file', file);

    if (formData) {
      setLoadingUploadImage(true);

      return uploadFile(formData)
        .then((fileName) => {
          // set form
          form.setValue('image', fileName);
          setImageName(fileName);
          setLoadingUploadImage(false);
        })
        .catch(() => {
          setLoadingUploadImage(false);
        });
    }
  };

  function onSubmit(data: CreateUpdateIndicateurProps) {
    setError(null);
    setLoading(true);

    console.log('la data', data.description);
    if (indicateur) {
      data.image = imageName || indicateur.image;
      return dispatch(
        updateIndicateur({
          indicateurId: indicateur.indicateurId,
          indicateur: data,
        })
      )
        .unwrap()
        .then(() => {
          form.reset();
          setLoading(false);
          return navigate('/admin/indicateurs');
        })
        .catch((e: ApiError) => {
          setLoading(false);
          setError(e.message);
        });
    } else {
      data.image = imageName || '';
      return dispatch(createIndicateur(data))
        .unwrap()
        .then(() => {
          form.reset();
          setLoading(false);
          return navigate('/admin/indicateurs');
        })
        .catch((e: ApiError) => {
          setLoading(false);
          setError(e.message);
        });
    }
  }

  const deleteImageIndicateur = (filename: string | null | undefined) => {
    setLoadingDeleteImage(true);

    setImageName(null);
    form.setValue('image', null);

    if (filename) {
      return deleteFile(filename)
        .then(() => {
          if (indicateur && indicateur.image) {
            let newData = indicateur;

            newData.image = null;

            return dispatch(
              updateIndicateur({
                indicateurId: indicateur.indicateurId,
                indicateur: newData,
              })
            );
          }
        })
        .then(() => {
          setLoadingDeleteImage(false);
        })
        .catch(() => {
          setLoadingDeleteImage(false);
        });
    }
  };

  const supprimerIndicateur = () => {
    setError(null);
    setLoadingDelete(true);

    if (indicateur) {
      if (indicateur && indicateur.image) {
        setImageName(null);
        return deleteFile(indicateur.image).then(() => {
          if (indicateur) {
            return dispatch(deleteIndicateur(indicateur))
              .unwrap()
              .then(() => {})
              .then(() => {
                form.reset();
                setLoadingDelete(true);
                return navigate('/admin/indicateurs');
              })
              .catch((e: ApiError) => {
                setLoadingDelete(true);
                setError(e.message);
              });
          }
        });
      } else {
        return dispatch(deleteIndicateur(indicateur))
          .unwrap()
          .then(() => {})
          .then(() => {
            form.reset();
            setLoadingDelete(true);
            return navigate('/admin/indicateurs');
          })
          .catch((e: ApiError) => {
            setLoadingDelete(true);
            setError(e.message);
          });
      }
    }
  };

  return (
    <AdminLayout>
      <AlertDialog>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Supprimer cet indicateur</AlertDialogTitle>
            <AlertDialogDescription>
              Confirmer vous la suppression de cet indicateur ?
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Annuler</AlertDialogCancel>
            <AlertDialogAction disabled={loadingDelete} onClick={() => supprimerIndicateur()}>
              Oui
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
        <div className='flex min-h-screen w-full flex-col'>
          <div className='flex flex-col sm:gap-4 sm:py-4 sm:pl-14'>
            <main className='grid flex-1 items-start gap-4 p-4 sm:px-6 sm:py-0 md:gap-8'>
              <Form {...form}>
                <form
                  onSubmit={form.handleSubmit(onSubmit)}
                  className='mx-auto grid w-full flex-1 auto-rows-max gap-4'
                >
                  <div className='flex items-center gap-4'>
                    <Button
                      variant='outline'
                      size='icon'
                      className='h-7 w-7'
                      onClick={() => cancel()}
                    >
                      <ChevronLeft className='h-4 w-4' />
                      <span className='sr-only'>Back</span>
                    </Button>

                    <h1 className='flex-1 shrink-0 whitespace-nowrap text-xl font-semibold tracking-tight sm:grow-0'>
                      {(indicateur && 'Modifier') || 'Ajouter'} un indicateur
                    </h1>
                  </div>

                  {/* Boutons Annuler et Enregistrer juste sous le titre */}
                  <div className='flex items-center gap-2'>
                    <Button type='button' onClick={() => cancel()} variant='outline' size='sm'>
                      Annuler
                    </Button>

                    <Button type='submit' size='sm' disabled={loading}>
                      {loading ? <ClipLoader color='white' loading size={20} /> : 'Enregistrer'}
                    </Button>
                  </div>

                  {error && (
                    <Alert variant='destructive'>
                      <AlertCircle className='h-4 w-4' />
                      <AlertTitle>Erreur</AlertTitle>
                      <AlertDescription>{error}</AlertDescription>
                    </Alert>
                  )}

                  <div className='grid gap-4 md:grid-cols-[1fr_1fr] lg:grid-cols-2 lg:gap-8'>
                    <div className='grid auto-rows-max items-start gap-4 lg:gap-8'>
                      <Card>
                        <CardHeader>
                          <CardTitle>Mon indicateur</CardTitle>
                        </CardHeader>
                        <CardContent>
                          <div className='grid gap-6'>
                            <div className='grid gap-3'>
                              <FormField
                                control={form.control}
                                name='name'
                                render={({ field }) => (
                                  <FormItem>
                                    <FormLabel>Nom *</FormLabel>
                                    <FormControl {...field}>
                                      <Input placeholder='Entrez le nom' />
                                    </FormControl>
                                    <FormMessage />
                                  </FormItem>
                                )}
                              />
                            </div>
                            <Card className='overflow-hidden'>
                              <CardHeader>
                                <CardTitle>Image</CardTitle>
                                <CardDescription>
                                  Image de couverture de l'indicateur
                                </CardDescription>
                              </CardHeader>
                              <CardContent>
                                <FormField
                                  control={form.control}
                                  name='image'
                                  render={({ field }) => (
                                    <div className='grid gap-2'>
                                      <input
                                        ref={fileInputRef}
                                        type='file'
                                        accept='image/*'
                                        onChange={handleImageInputChange}
                                        style={{ display: 'none' }}
                                      />

                                      {field && field.value ? (
                                        <div style={{ position: 'relative' }}>
                                          <button
                                            style={{ position: 'absolute', top: -15, right: -20 }}
                                            onClick={() => {
                                              deleteImageIndicateur(field.value);
                                            }}
                                            disabled={loadingDeleteImage}
                                            type='button'
                                            className='flex items-center px-3 py-1 bg-red-500 text-white rounded-md hover:bg-red-600 focus:outline-none'
                                          >
                                            <Trash />
                                          </button>
                                          <img
                                            style={{
                                              width: '416px',
                                              height: '291px',
                                              objectFit: 'cover',
                                            }}
                                            alt='Product image'
                                            className='aspect-square w-full rounded-md object-cover'
                                            height='300'
                                            src={`${API_URL}/uploads/${field.value}`}
                                            width='300'
                                          />
                                        </div>
                                      ) : (
                                        <div className='grid grid-cols-3 gap-2'>
                                          <button
                                            type='button'
                                            onClick={() => {
                                              if (fileInputRef.current) {
                                                (fileInputRef.current as HTMLInputElement).click();
                                              }
                                            }}
                                            disabled={loadingUploadImage}
                                            className='flex aspect-square w-full items-center justify-center rounded-md border border-dashed'
                                          >
                                            <Upload className='h-4 w-4 text-muted-foreground' />
                                            <span className='sr-only'>Upload</span>
                                          </button>
                                        </div>
                                      )}
                                    </div>
                                  )}
                                />
                              </CardContent>
                            </Card>

                            <div className='grid gap-3'>
                              <FormField
                                control={form.control}
                                name='smalldescription'
                                render={({ field }) => (
                                  <FormItem>
                                    <FormLabel>Petite description *</FormLabel>
                                    <FormControl {...field}>
                                      <Textarea
                                        className='min-h-32'
                                        placeholder='Entrez la petite description'
                                        value={field.value}
                                      />
                                    </FormControl>
                                    <FormMessage />
                                  </FormItem>
                                )}
                              />
                            </div>
                            <div className='grid gap-3'>
                              <FormField
                                control={form.control}
                                name='description'
                                render={({ field }) => (
                                  <FormItem>
                                    <FormLabel>Description *</FormLabel>
                                    <FormControl {...field}>
                                      <EditorTinymce
                                        value={field.value || ''}
                                        onEditorChange={(data) => field.onChange(data)}
                                      />
                                    </FormControl>
                                    <FormMessage />
                                  </FormItem>
                                )}
                              />
                            </div>
                          </div>
                        </CardContent>
                      </Card>
                    </div>
                  </div>
                </form>
              </Form>
            </main>
          </div>
        </div>
      </AlertDialog>
    </AdminLayout>
  );
};
export default CreateUpdateIndicateurPageAD;
