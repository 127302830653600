import { useState } from "react";

import { UpdatePasswordProps } from "../../types/User";
import { ApiError } from "../../types/Api";



import { AppDispatch, RootState } from "../../store/configureStore";
import { useDispatch, useSelector } from "react-redux";
import { updatePassword } from "../../store/modules/auth";

import { ClipLoader } from "react-spinners";
import { useForm } from "react-hook-form";


import AlertBloc from "../AlertBloc";

const UpdatePasswordForm = () => {
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm<UpdatePasswordProps>();

    const [loadingUpdate, setLoadingUpdate] =
        useState<boolean>(false);
    const [updateIsOk, setUpdateIsOk] =
        useState<boolean>(false);
    const [error, setError] =
        useState<string | null>(null);



    const dispatch = useDispatch<AppDispatch>();

    const onSubmit = (data: UpdatePasswordProps) => {
        setError(null)
        setLoadingUpdate(true)
        setUpdateIsOk(false)

        if (data.repeatPassword !== data.password) {
            setError('Veuillez renseigner un mot de passe identique')
            setLoadingUpdate(false)
        } else {
            return dispatch(updatePassword(data.password)).unwrap().then(() => {
                reset()
                setLoadingUpdate(false)
                setUpdateIsOk(true)
            }).catch(() => {
                setError('Une erreur est survenue')
                setLoadingUpdate(false)
            })
        }
    };

    return (
        <div className="bg-white rounded-xl shadow p-4 sm:p-7 dark:bg-neutral-900">
            <div className="mb-8">
                <h2 className="text-xl font-bold text-gray-800 dark:text-neutral-200">
                    Mon mot de passe
                </h2>
                <p className="text-sm text-gray-600 dark:text-neutral-400">
                    Modifier mon mot de passe
                </p>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
                {/* Grid */}
                <div className="grid sm:grid-cols-12 gap-2 sm:gap-6">
                    <div className="sm:col-span-3">
                        <label
                            htmlFor="af-account-password"
                            className="inline-block text-sm text-gray-800 mt-2.5 dark:text-neutral-200"
                        >
                            Votre nouveau mot de passe
                        </label>
                    </div>

                    <div className="sm:col-span-9">
                        <input
                            id="af-account-password"
                            type="password"
                            {...register("password", { required: "Mot de passe requis" })}
                            className="py-2 px-3 pe-11 block w-full border-gray-200 shadow-sm text-sm rounded-lg focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600"
                        />
                    </div>

                    <div className="sm:col-span-3">
                        <label
                            htmlFor="af-account-email"
                            className="inline-block text-sm text-gray-800 mt-2.5 dark:text-neutral-200"
                        >
                            Répeter votre nouveau mot de passe
                        </label>
                    </div>

                    <div className="sm:col-span-9">
                        <input
                            id="af-account-password-repeat"
                            type="password"
                            {...register("repeatPassword", { required: "Mot de passe requis" })}
                            className="py-2 px-3 pe-11 block w-full border-gray-200 shadow-sm text-sm rounded-lg focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600"
                        />
                    </div>
                </div>

                {error && (
                    <AlertBloc
                        isError={true}
                        title="Erreur"
                        text={error}
                    />
                )}
                {updateIsOk && (
                    <AlertBloc
                        isError={false}
                        title="Mot de passe modifié"
                        text="Votre mot de passe a été modifié avec succès"
                    />
                )}
                {!updateIsOk && (
                    <div className="mt-5 flex justify-end gap-x-2">
                        <button
                            type="submit"
                            className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none"
                        >
                            {loadingUpdate ? (
                                <ClipLoader color="white" loading size={20} />
                            ) : (
                                "Modifier"
                            )}
                        </button>
                    </div>

                )}

            </form>
        </div>
    );
};

export default UpdatePasswordForm;
