export default function Features() {
  return (
    <div id='offres' className='max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto'>
      <div className='max-w-2xl mx-auto text-center mb-10 lg:mb-14'>
        <h2 className='text-2xl font-bold md:text-4xl md:leading-tight dark:text-white'>
          Nos offres
        </h2>
        <p className='mt-1 text-gray-600 dark:text-gray-400'>Découvrez nos offres</p>
      </div>
      <div className='grid sm:grid-cols-2 lg:grid-cols-4 items-center gap-12'>
        {/* Icon Block */}
        <div>
          <div className='relative flex justify-center items-center size-12 bg-white rounded-xl before:absolute before:-inset-px before:-z-[1] before:bg-gradient-to-br before:from-blue-600 before:via-transparent before:to-violet-600 before:rounded-xl dark:bg-slate-900'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'
              stroke='currentColor'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
              className='lucide lucide-hammer flex-shrink-0 size-6 text-blue-600 dark:text-blue-500'
            >
              <path d='m15 12-8.373 8.373a1 1 0 1 1-3-3L12 9' />
              <path d='m18 15 4-4' />
              <path d='m21.5 11.5-1.914-1.914A2 2 0 0 1 19 8.172V7l-2.26-2.26a6 6 0 0 0-4.202-1.756L9 2.96l.92.82A6.18 6.18 0 0 1 12 8.4V10l2 2h1.172a2 2 0 0 1 1.414.586L18.5 14.5' />
            </svg>
          </div>
          <div className='mt-5'>
            <h3 className='text-lg font-semibold text-gray-800 dark:text-white'>
              Outils d'Analyse Prédictive
            </h3>
            <p className='mt-1 text-gray-600 dark:text-gray-400'>
              Utilisez l'intelligence artificielle pour anticiper les mouvements du marché avec une
              précision remarquable.
            </p>
          </div>
        </div>
        {/* End Icon Block */}
        {/* Icon Block */}
        <div>
          <div className='relative flex justify-center items-center size-12 bg-white rounded-xl before:absolute before:-inset-px before:-z-[1] before:bg-gradient-to-br before:from-blue-600 before:via-transparent before:to-violet-600 before:rounded-xl dark:bg-slate-900'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'
              stroke='currentColor'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
              className='lucide lucide-wallet-minimal flex-shrink-0 size-6 text-blue-600 dark:text-blue-500'
            >
              <path d='M17 14h.01' />
              <path d='M7 7h12a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h14' />
            </svg>
          </div>
          <div className='mt-5'>
            <h3 className='text-lg font-semibold text-gray-800 dark:text-white'>Indicateurs</h3>
            <p className='mt-1 text-gray-600 dark:text-gray-400'>
              Découvrez nos indicateurs faits maison pour des analyses techniques précises et
              optimisées, vous aidant à prendre des décisions éclairées sur vos investissements en
              cryptomonnaies.
            </p>
          </div>
        </div>
        {/* End Icon Block */}
        {/* Icon Block */}
        <div>
          <div className='relative flex justify-center items-center size-12 bg-white rounded-xl before:absolute before:-inset-px before:-z-[1] before:bg-gradient-to-br before:from-blue-600 before:via-transparent before:to-violet-600 before:rounded-xl dark:bg-slate-900'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'
              stroke='currentColor'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
              className='lucide lucide-radio flex-shrink-0 size-6 text-blue-600 dark:text-blue-500'
            >
              <path d='M4.9 19.1C1 15.2 1 8.8 4.9 4.9' />
              <path d='M7.8 16.2c-2.3-2.3-2.3-6.1 0-8.5' />
              <circle cx='12' cy='12' r='2' />
              <path d='M16.2 7.8c2.3 2.3 2.3 6.1 0 8.5' />
              <path d='M19.1 4.9C23 8.8 23 15.1 19.1 19' />
            </svg>
          </div>
          <div className='mt-5'>
            <h3 className='text-lg font-semibold text-gray-800 dark:text-white'>
              Signaux de Trading bassés sur nos analyses
            </h3>
            <p className='mt-1 text-gray-600 dark:text-gray-400'>
              Recevez des alertes informatives avec des scénarios haussiers et baissiers pour
              maximiser vos opportunités de trading
            </p>
          </div>
        </div>
        {/* End Icon Block */}
        {/* Icon Block */}
        <div>
          <div className='relative flex justify-center items-center size-12 bg-white rounded-xl before:absolute before:-inset-px before:-z-[1] before:bg-gradient-to-br before:from-blue-600 before:via-transparent before:to-violet-600 before:rounded-xl dark:bg-slate-900'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'
              stroke='currentColor'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
              className='lucide lucide-book-open flex-shrink-0 size-6 text-blue-600 dark:text-blue-500'
            >
              <path d='M2 3h6a4 4 0 0 1 4 4v14a3 3 0 0 0-3-3H2z' />
              <path d='M22 3h-6a4 4 0 0 0-4 4v14a3 3 0 0 1 3-3h7z' />
            </svg>
          </div>
          <div className='mt-5'>
            <h3 className='text-lg font-semibold text-gray-800 dark:text-white'>
              Formation et Ressources
            </h3>
            <p className='mt-1 text-gray-600 dark:text-gray-400'>
              Améliorez vos compétences de trading avec nos tutoriels, webinaires, et analyses de
              marché exclusives.
            </p>
          </div>
        </div>
        {/* End Icon Block */}
      </div>
    </div>
  );
}
