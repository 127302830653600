import { createSlice, createAsyncThunk, ActionReducerMapBuilder } from '@reduxjs/toolkit';

import { schema, NormalizedSchema, normalize } from 'normalizr';

import { callApi } from '../api';
import { Comment } from '../../types/Comment';

const dataSchema = new schema.Entity('comments', {}, { idAttribute: 'commentId' });

type DataNormalized = NormalizedSchema<{ comments: { [key: string]: Comment } }, string[]>;

interface State {
  data: DataNormalized['entities']['comments'];
  status: 'idle' | 'loading' | 'failed';
  totalCount: number;
  totalPages: number;
}
const initialState: State = {
  data: {},
  status: 'idle',
  totalCount: 0,
  totalPages: 0,
};

export const getComments = createAsyncThunk('getComments', async (page: number) => {
  const response = await callApi(`/comments/page/${page}`, 'GET', null);
  return response;
});

export const deleteComment = createAsyncThunk('deleteComment', async (comment: Comment) => {
  const response = await callApi(`/comments/${comment.commentId}`, 'DELETE', null, comment);
  return response;
});

export const updateComment = createAsyncThunk(
  'updateComment',
  async ({ comment, text }: { comment: Comment; text: string }) => {
    const response = await callApi(`/comments/${comment.commentId}`, 'PUT', null, { text });
    return response;
  }
);

const CommentsSlice = createSlice({
  name: 'comments',
  initialState,
  reducers: {
    // You can have other non-async actions here
  },
  extraReducers: (builder: ActionReducerMapBuilder<State>) => {
    builder
      /*------------------------------------------------
                          getComments()
      --------------------------------------------------*/
      .addCase(getComments.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getComments.fulfilled, (state, action) => {
        state.status = 'idle';
        const data = action.payload;

        state.status = 'idle';
        state.data =
          (data &&
            data.paginateData &&
            normalize(data.paginateData, [dataSchema]).entities.comments) ||
          {};
        state.totalPages = (data && data.totalPages) || 0;
        state.totalCount = (data && data.totalCount) || 0;
      })
      .addCase(getComments.rejected, (state, action) => {
        state.status = 'failed';
      })

      /* ------------------------------------------------
             updateComment()
--------------------------------------------
 */
      .addCase(updateComment.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateComment.fulfilled, (state, action) => {
        state.status = 'idle';
        const updatedData = action.payload;
        state.data[updatedData.commentId] = updatedData;
      })
      .addCase(updateComment.rejected, (state, action) => {
        state.status = 'failed';
      })
      /* ------------------------------------------------
                    deleteComment()
     ------------------------------------------------- */
      .addCase(deleteComment.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteComment.fulfilled, (state, action) => {
        state.status = 'idle';

        const newData = { ...state.data };
        delete newData[action.payload.commentId];
        state.data = newData;
      })
      .addCase(deleteComment.rejected, (state, action) => {
        state.status = 'failed';
      });
  },
});

export default CommentsSlice.reducer;
