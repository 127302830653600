import React from "react";
import ReactDOM from "react-dom/client";

import reportWebVitals from "./reportWebVitals";
import Router from "./Router";
import { Provider } from "react-redux";
import { getPersistor, getStore } from "./store";

import { PersistGate } from "redux-persist/integration/react";

import "preline/preline";
import "./styles/index.css";

const myStore = getStore();
const myPersistor = getPersistor();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <Provider store={myStore}>
      <PersistGate loading={null} persistor={myPersistor}>
        <Router />
      </PersistGate>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
