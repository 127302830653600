import { useEffect } from 'react';
import { Link } from 'react-router-dom';

import API_URL from '../../constants/config';

import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store/configureStore';
import { getUsers } from '../../store/modules/users';
import { getAnalyses } from '../../store/modules/analyses';
import { getComments } from '../../store/modules/comments';

import { orderBy, map } from 'lodash';
import { ArrowUpRight, DollarSign, Users } from 'lucide-react';

import AdminLayout from '../../components/layout/AdminLayout';
import { Avatar, AvatarFallback, AvatarImage } from '../../components/ui/avatar';

import moment from 'moment';

import { Button } from '../../components/ui/button';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '../../components/ui/card';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '../../components/ui/table';

const DashboardPageAD = () => {
  const dispatch = useDispatch<AppDispatch>();

  const users = useSelector((state: RootState) => state.users.data);

  const comments = useSelector((state: RootState) => state.comments.data);

  const countAnalyses = useSelector((state: RootState) => state.analyses.totalCount);
  const countUsers = useSelector((state: RootState) => state.users.totalCount);
  const countComments = useSelector((state: RootState) => state.comments.totalCount);

  useEffect(() => {
    dispatch(getUsers(1));
    dispatch(getAnalyses(1));
    dispatch(getComments(1));
  }, []);

  return (
    <AdminLayout>
      <div className='grid gap-4 md:grid-cols-2 md:gap-8 lg:grid-cols-4'>
        <Card>
          <CardHeader className='flex flex-row items-center justify-between space-y-0 pb-2'>
            <CardTitle className='text-sm font-medium'>Utilisateurs</CardTitle>
            <DollarSign className='h-4 w-4 text-muted-foreground' />
          </CardHeader>
          <CardContent>
            <div className='text-2xl font-bold'>{countUsers || 0}</div>
            {/* <p className='text-xs text-muted-foreground'>+20.1% from last month</p> */}
          </CardContent>
        </Card>
        {/*   <Card>
          <CardHeader className='flex flex-row items-center justify-between space-y-0 pb-2'>
            <CardTitle className='text-sm font-medium'>Utilisateurs affiliés</CardTitle>
            <DollarSign className='h-4 w-4 text-muted-foreground' />
          </CardHeader>
          <CardContent>
            <div className='text-2xl font-bold'>
              {(usersAffiliation && values(usersAffiliation).length) || 0}
            </div>
           
          </CardContent>
        </Card> */}
        <Card>
          <CardHeader className='flex flex-row items-center justify-between space-y-0 pb-2'>
            <CardTitle className='text-sm font-medium'>Analyses</CardTitle>
            <Users className='h-4 w-4 text-muted-foreground' />
          </CardHeader>
          <CardContent>
            <div className='text-2xl font-bold'>{countAnalyses || 0}</div>
            {/* <p className='text-xs text-muted-foreground'>+180.1% from last month</p> */}
          </CardContent>
        </Card>
        <Card>
          <CardHeader className='flex flex-row items-center justify-between space-y-0 pb-2'>
            <CardTitle className='text-sm font-medium'>Commentaires</CardTitle>
            <Users className='h-4 w-4 text-muted-foreground' />
          </CardHeader>
          <CardContent>
            <div className='text-2xl font-bold'>{countComments || 0}</div>
            {/* <p className='text-xs text-muted-foreground'>+180.1% from last month</p> */}
          </CardContent>
        </Card>
        {/*    <Card>
          <CardHeader className='flex flex-row items-center justify-between space-y-0 pb-2'>
            <CardTitle className='text-sm font-medium'>Sales</CardTitle>
            <CreditCard className='h-4 w-4 text-muted-foreground' />
          </CardHeader>
          <CardContent>
            <div className='text-2xl font-bold'>+12,234</div>
            <p className='text-xs text-muted-foreground'>+19% from last month</p>
          </CardContent>
        </Card>
        <Card>
          <CardHeader className='flex flex-row items-center justify-between space-y-0 pb-2'>
            <CardTitle className='text-sm font-medium'>Active Now</CardTitle>
            <Activity className='h-4 w-4 text-muted-foreground' />
          </CardHeader>
          <CardContent>
            <div className='text-2xl font-bold'>+573</div>
            <p className='text-xs text-muted-foreground'>+201 since last hour</p>
          </CardContent>
        </Card> */}
      </div>
      <div className='grid gap-4 md:gap-8 lg:grid-cols-2 xl:grid-cols-3'>
        <Card className='xl:col-span-2'>
          <CardHeader className='flex flex-row items-center'>
            <div className='grid gap-2'>
              <CardTitle>Commentaires</CardTitle>
              <CardDescription>Derniers commentaires</CardDescription>
            </div>
            <Button asChild size='sm' className='ml-auto gap-1'>
              <Link to='/admin/commentaires'>
                Voir tout
                <ArrowUpRight className='h-4 w-4' />
              </Link>
            </Button>
          </CardHeader>
          <CardContent>
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Commentaire</TableHead>
                  <TableHead>Date</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {map(orderBy(comments, ['createdAt'], ['desc']), (c) => (
                  <TableRow key={c.commentId}>
                    <TableCell>
                      <div className='font-medium'>{(c.user && c.user.pseudo) || ''}</div>
                      <div className='hidden text-sm text-muted-foreground md:inline'>
                        {c.content || ''}
                      </div>
                    </TableCell>
                    <TableCell className='text-right'>
                      {moment(c.createdAt).format('DD-MM-YYYY HH:mm')}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </CardContent>
        </Card>
        <Card>
          <CardHeader>
            <CardTitle>Dernières inscriptions</CardTitle>
          </CardHeader>
          <CardContent className='grid gap-8'>
            {map(orderBy(users, ['createdAt'], ['desc']), (u) => (
              <div className='flex items-center gap-4' key={u.userId}>
                <Avatar className='hidden h-9 w-9 sm:flex'>
                  <AvatarImage
                    src={u && u.avatar ? `${API_URL}/uploads/${u.avatar}` : '/avatar.webp'}
                    alt='Avatar'
                  />
                  <AvatarFallback>{u.pseudo}</AvatarFallback>
                </Avatar>

                <div className='grid gap-1'>
                  <p className='text-sm font-medium leading-none'>{u.pseudo}</p>
                  <p className='text-sm text-muted-foreground'>{u.email}</p>
                </div>

                <div className='ml-auto font-medium'>{u.exchange || ''}</div>
                <div className='ml-auto font-medium'>
                  {moment(u.createdAt).format('DD-MM-YYYY HH:mm')}
                </div>
              </div>
            ))}
          </CardContent>
        </Card>
      </div>
    </AdminLayout>
  );
};
export default DashboardPageAD;
