import { ReactNode, useState } from "react";

import { Comment, UpdateCommentProps } from "../../types/Comment";
import { ApiError } from "../../types/Api";

import { useDispatch } from "react-redux";
import { AppDispatch } from "../../store/configureStore";
import { updateComment } from "../../store/modules/comments";

import { useForm } from "react-hook-form";
import { AlertCircle } from "lucide-react";
import { ClipLoader } from "react-spinners";

import { Button } from "../ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogClose,
} from "../ui/dialog";
import { Textarea } from "../../components/ui/textarea";
import { Label } from "../ui/label";
import { Alert, AlertDescription, AlertTitle } from "../ui/alert";

export type Props = {
  openModal: boolean;
  closeModal: () => void;
  commentSelected?: Comment | null;
  dialogTrigger: ReactNode;
};

const ModalEditComment: React.FC<Props> = ({
  openModal,
  closeModal,
  commentSelected,
  dialogTrigger,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const { register, handleSubmit, reset } = useForm<UpdateCommentProps>({
    defaultValues: commentSelected || {},
    resetOptions: {
      keepDirtyValues: true,
    },
  });

  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const onSubmit = (data: UpdateCommentProps) => {
    setError(null);
    setLoading(true);

    if (commentSelected) {
      setLoading(true);

      return dispatch(
        updateComment({ comment: commentSelected, text: data.content })
      )
        .unwrap()
        .then(() => {
          closeModal();
          setLoading(false);
        })
        .catch((e: ApiError) => {
          setLoading(false);
          setError(e.message);
        });
    }
  };

  return (
    <Dialog>
      {dialogTrigger}
      {openModal && (
        <DialogContent className="sm:max-w-[425px]">
          <form onSubmit={handleSubmit(onSubmit)}>
            <DialogHeader>
              <DialogTitle>Modifier un commentaire</DialogTitle>
              <DialogDescription>
                Make changes to your profile here. Click save when you're done.
              </DialogDescription>
            </DialogHeader>
            <div className="grid gap-4 py-4">
              <div className="grid grid-cols-4 items-center gap-4">
                <Label htmlFor="content" className="text-right">
                  Text
                </Label>
                <Textarea
                  id="content"
                  placeholder="Entrez le text"
                  defaultValue={commentSelected?.content || ""}
                  {...register("content", { required: "Text requis" })}
                  className="min-h-32 col-span-3"
                />
              </div>

              {error && (
                <Alert variant="destructive">
                  <AlertCircle className="h-4 w-4" />
                  <AlertTitle>Erreur</AlertTitle>
                  <AlertDescription>{error}</AlertDescription>
                </Alert>
              )}
            </div>
            <DialogFooter>
              <DialogClose
                asChild
                onClick={() => {
                  setError(null);
                  reset();
                }}
              >
                <Button variant="secondary">Annuler</Button>
              </DialogClose>
              <Button type="submit">
                {" "}
                {loading ? (
                  <ClipLoader color="white" loading size={20} />
                ) : (
                  "Enregistrer"
                )}
              </Button>
            </DialogFooter>
          </form>
        </DialogContent>
      )}
    </Dialog>
  );
};

export default ModalEditComment;
