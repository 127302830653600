import { Link, useLocation, useNavigate } from 'react-router-dom';

import { CircleUser, Menu, Package2 } from 'lucide-react';

import { AppDispatch, resetAllSlices } from '../../store/configureStore';
import { useDispatch } from 'react-redux';

import { Button } from '../../components/ui/button';

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '../../components/ui/dropdown-menu';
import { Sheet, SheetContent, SheetTrigger } from '../../components/ui/sheet';

export function NavbarAD() {
  const navigate = useNavigate();
  const dispath = useDispatch<AppDispatch>();

  const logoutUser = () => {
    dispath(resetAllSlices());
    navigate('/auth/login');
  };

  const location = useLocation();
  const currentPath = location.pathname;

  const isActiveRoute = (path: string): boolean => {
    if (path === currentPath) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <header className='sticky top-0 flex h-16 items-center gap-4 border-b bg-background px-4 md:px-6'>
      <nav className='hidden flex-col gap-6 text-lg font-medium md:flex md:flex-row md:items-center md:gap-5 md:text-sm lg:gap-6'>
        <Link to='/' className='flex items-center gap-2 text-lg font-semibold md:text-base'>
          <Package2 className='h-6 w-6' />
          <span className='sr-only'>Jarvis</span>
        </Link>
        <Link
          to='/admin/dashboard'
          className={`${
            !isActiveRoute('/admin/dashboard') ? 'text-muted-foreground' : ''
          } transition-colors hover:text-foreground`}
        >
          Accueil
        </Link>
        <Link
          to='/admin/analyses'
          className={`${
            !isActiveRoute('/admin/analyses') ? 'text-muted-foreground' : ''
          } transition-colors hover:text-foreground`}
        >
          Analyses
        </Link>
        <Link
          to='/admin/indicateurs'
          className={`${
            !isActiveRoute('/admin/indicateurs') ? 'text-muted-foreground' : ''
          } transition-colors hover:text-foreground`}
        >
          Indicateurs
        </Link>
        <Link
          to='/admin/cryptos'
          className={`${
            !isActiveRoute('/admin/cryptos') ? 'text-muted-foreground' : ''
          } transition-colors hover:text-foreground`}
        >
          Cryptos
        </Link>
        <Link
          to='/admin/utilisateurs'
          className={`${
            !isActiveRoute('/admin/utilisateurs') ? 'text-muted-foreground' : ''
          } transition-colors hover:text-foreground`}
        >
          Utilisateurs
        </Link>
        <Link
          to='/admin/commentaires'
          className={`${
            !isActiveRoute('/admin/commentaires') ? 'text-muted-foreground' : ''
          } transition-colors hover:text-foreground`}
        >
          Commentaires
        </Link>
      </nav>
      <Sheet>
        <SheetTrigger asChild>
          <Button variant='outline' size='icon' className='shrink-0 md:hidden'>
            <Menu className='h-5 w-5' />
            <span className='sr-only'>Toggle navigation menu</span>
          </Button>
        </SheetTrigger>
        <SheetContent side='left'>
          <nav className='grid gap-6 text-lg font-medium'>
            <Link to='/' className='flex items-center gap-2 text-lg font-semibold'>
              <Package2 className='h-6 w-6' />
              <span className='sr-only'>Mamos Crypto</span>
            </Link>

            <Link
              to='/admin/dashboard'
              className={`${
                !isActiveRoute('/admin/dashboard') ? 'text-muted-foreground' : ''
              } hover:text-foreground`}
            >
              Accueil
            </Link>
            <Link
              to='/admin/analyses'
              className={`${
                !isActiveRoute('/admin/analyses') ? 'text-muted-foreground' : ''
              } hover:text-foreground`}
            >
              Analyses
            </Link>
            <Link
              to='/admin/cryptos'
              className={`${
                !isActiveRoute('/admin/cryptos') ? 'text-muted-foreground' : ''
              } hover:text-foreground`}
            >
              Cryptos
            </Link>
            <Link
              to='/admin/utilisateurs'
              className={`${
                !isActiveRoute('/admin/utilisateurs') ? 'text-muted-foreground' : ''
              } hover:text-foreground`}
            >
              Utilisateurs
            </Link>
            <Link
              to='/admin/commentaires'
              className={`${
                !isActiveRoute('/admin/commentaires') ? 'text-muted-foreground' : ''
              } hover:text-foreground`}
            >
              Commentaires
            </Link>
          </nav>
        </SheetContent>
      </Sheet>
      <div className='flex w-full items-center gap-4 md:ml-auto md:gap-2 lg:gap-4'>
        <form className='ml-auto flex-1 sm:flex-initial'>
          <div className='relative'></div>
        </form>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant='secondary' size='icon' className='rounded-full'>
              <CircleUser className='h-5 w-5' />
              <span className='sr-only'>Toggle user menu</span>
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align='end'>
            <DropdownMenuItem onClick={() => logoutUser()}>Déconnexion</DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    </header>
  );
}
