import { useLayoutEffect, useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate, Outlet, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { HelmetProvider } from 'react-helmet-async';

import { User } from './types/User';
import { ApiError } from './types/Api';

import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, resetAllSlices, RootState } from './store/configureStore';
import { loadUser } from './store/modules/auth';

import { includes } from 'lodash';

import HomePage from './pages/HomePage';
import AnalysesPage from './pages/analyses/AnalysesPage';
import RegisterPage from './pages/auth/RegisterPage';
import AnalysePage from './pages/analyses/AnalysePage';
import LoginPage from './pages/auth/LoginPage';
import AnalysesPageAD from './pages/admin/AnalysesPageAD';
import CryptosPageAD from './pages/admin/CryptosPageAD';
import NotFoundPage from './pages/NotFoundPage';
import DashboardPageAD from './pages/admin/DashboardPageAD';

import CreateUpdateAnalysePageAD from './pages/admin/CreateUpdateAnalysePageAD';
import UtilisateursPageAD from './pages/admin/UtilisateursPageAD';
import ReservedPage from './pages/ReservedPage';
import CommentairesPageAD from './pages/admin/CommentairesPageAD';
import ProfilPage from './pages/user/ProfilPage';
import AffiliationExplikPage from './pages/AffiliationExplikPage';
import PasswordResetPage from './pages/auth/PasswordResetPage';
import IndicateursPageAD from './pages/admin/IndicateursPageAD';
import CreateUpdateIndicateurPageAD from './pages/admin/CreateUpdateIndicateurPageAD';
import IndicateursPage from './pages/indicateurs/IndicateursPage';
import IndicateurPage from './pages/indicateurs/IndicateurPage';

export type ProtectedRouteProps = {
  user: User | null;
  requiredRole: string;
};

// Initialisation de Google Analytics
ReactGA.initialize('G-GYX1M9GF3S');

const RouterListener = ({
  children,
  user,
  reloadUser,
  logoutUser,
}: {
  children: any;
  user: User | null;
  reloadUser: Function;
  logoutUser: Function;
}) => {
  // apeler a chaque changemment de page
  const location = useLocation();
  const [loadingUser, setLoadingUser] = useState(false);

  useLayoutEffect(() => {
    if (user && !loadingUser) {
      if (navigator.onLine) {
        setLoadingUser(true);
        reloadUser()
          .catch((e: ApiError) => {
            if (e.message && e.message === 'USER_NOT_FOUND') {
              logoutUser();
            }
          })
          .finally(() => setLoadingUser(false));
      }
    }
  }, [location]);

  useEffect(() => {
    // Suivi des pages vues

    ReactGA.send({ hitType: 'pageview', page: location.pathname + location.search });
  }, [location]);

  return children;
};

const ProtectedRoute = ({ user, requiredRole }: ProtectedRouteProps) => {
  const location = useLocation();

  if (!user || !includes(user.roles, requiredRole)) {
    return <Navigate to='/reserved' state={{ from: location }} replace={true} />;
  }

  return <Outlet />;
};

const Router = function () {
  const user = useSelector((state: RootState) => state.auth.user);
  const dispath = useDispatch<AppDispatch>();

  return (
    <HelmetProvider>
      <BrowserRouter basename='/'>
        <RouterListener
          user={user}
          reloadUser={() => dispath(loadUser()).unwrap()}
          logoutUser={() => {
            dispath(resetAllSlices());
          }}
        >
          <Routes>
            <Route path='/' element={<HomePage />} />
            <Route path='/auth/register' element={<RegisterPage />} />
            <Route path='/auth/login' element={<LoginPage />} />
            <Route path='/auth/password' element={<PasswordResetPage />} />
            <Route path='/reserved' element={<ReservedPage />} />
            <Route path='/affiliation' element={<AffiliationExplikPage />} />

            <Route path='/indicateurs' element={<IndicateursPage />} />
            <Route path='/indicateur/:indicateurId' element={<IndicateurPage />} />
            <Route element={<ProtectedRoute user={user} requiredRole='USER' />}>
              <Route path='/profil' element={<ProfilPage />} />

              <Route path='/analyses' element={<AnalysesPage />} />
              <Route path='/analyse/:analyseId' element={<AnalysePage />} />
            </Route>

            <Route path='/auth/inscription' element={<RegisterPage />} />
            <Route element={<ProtectedRoute user={user} requiredRole='ADMIN' />}>
              <Route path='/admin/dashboard' element={<DashboardPageAD />} />
              <Route path='/admin/analyses' element={<AnalysesPageAD />} />
              <Route path='/admin/analyses/create' element={<CreateUpdateAnalysePageAD />} />
              <Route
                path='/admin/analyses/edit/:analyseId'
                element={<CreateUpdateAnalysePageAD />}
              />
              <Route path='/admin/indicateurs' element={<IndicateursPageAD />} />
              <Route path='/admin/indicateurs/create' element={<CreateUpdateIndicateurPageAD />} />
              <Route
                path='/admin/indicateurs/edit/:indicateurId'
                element={<CreateUpdateIndicateurPageAD />}
              />

              <Route path='/admin/cryptos' element={<CryptosPageAD />} />
              <Route path='/admin/utilisateurs' element={<UtilisateursPageAD />} />
              <Route path='/admin/commentaires' element={<CommentairesPageAD />} />
            </Route>
            <Route path='/404' element={<NotFoundPage />} />
            {/* Route 404 */}
            <Route path='*' element={<NotFoundPage />} />
          </Routes>
        </RouterListener>
      </BrowserRouter>
    </HelmetProvider>
  );
};

export default Router;
