import { useEffect, useState } from "react";

import { Crypto } from "../../types/Crypto";

import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store/configureStore";
import { deleteCrypto, getCryptos } from "../../store/modules/cryptos";

import { map, values, orderBy } from "lodash";
import moment from "moment";
import { Edit, Trash, PlusCircle } from "lucide-react";

import { Button } from "../../components/ui/button";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../../components/ui/card";
import { DialogTrigger } from "../../components/ui/dialog";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components/ui/table";
import { Tabs, TabsContent } from "../../components/ui/tabs";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "../../components/ui/alert-dialog";
import AdminLayout from "../../components/layout/AdminLayout";
import ModalAddEditCrypto from "../../components/cryptos/ModalAddEditCrypto";
import ModalConfim from "../../components/modals/ModalConfim";

const CryptosPageAD = () => {
  const dispatch = useDispatch<AppDispatch>();
  const cryptos = useSelector((state: RootState) => state.cryptos.data);

  const [openModalCreateOrEdit, setOpenModalCreateOrEdit] =
    useState<boolean>(false);
  const [openModalDelete, setOpenModalDelete] = useState<boolean>(false);
  const [loadingDelete, setLoadingDelete] = useState<boolean>(false);
  const [cryptoSelected, setCryptoSelected] = useState<Crypto | null>(null);

  useEffect(() => {
    dispatch(getCryptos());
  }, []);

  const actionDelete = () => {
    setLoadingDelete(true);

    if (cryptoSelected) {
      return dispatch(deleteCrypto(cryptoSelected))
        .unwrap()
        .then(() => {
          setLoadingDelete(false);
          setCryptoSelected(null);
          setOpenModalDelete(false);
        })
        .catch(() => {
          setLoadingDelete(false);
        });
    }
  };

  return (
    <AdminLayout>
      <AlertDialog>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Supprimer cet crypto</AlertDialogTitle>
            <AlertDialogDescription>
              Confirmer vous la suppresion de cet crypto ? les analyses lié
              seront supprimer
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel
              onClick={() => {
                actionDelete();
              }}
            >
              Annuler
            </AlertDialogCancel>
            <AlertDialogAction
              disabled={loadingDelete}
              onClick={() => actionDelete()}
            >
              Oui
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>

        {openModalDelete && (
          <ModalConfim
            openModal={openModalDelete}
            closeModal={() => {
              setCryptoSelected(null);
              setOpenModalDelete(false);
            }}
            loading={loadingDelete}
            actionConfirm={() => actionDelete()}
            title="Supprimer cet crypto"
            text="Confirmer vous la suppresion de cet crypto"
          />
        )}
        <div className="flex min-h-screen w-full flex-col">
          <div className="flex flex-col sm:gap-4 sm:py-4 sm:pl-14">
            <main className="grid flex-1 items-start gap-4 p-4 sm:px-6 sm:py-0 md:gap-8">
              <Tabs defaultValue="all">
                <TabsContent value="all">
                  <Card>
                    <CardHeader>
                      <CardTitle>Cryptos</CardTitle>
                    </CardHeader>

                    <CardContent>
                      <ModalAddEditCrypto
                        openModal={openModalCreateOrEdit}
                        closeModal={() => {
                          setCryptoSelected(null);
                          setOpenModalCreateOrEdit(false);
                        }}
                        cryptoSelected={cryptoSelected}
                        dialogTrigger={
                          <DialogTrigger asChild>
                            <Button
                              size="sm"
                              className="h-8 gap-1 mb-4"
                              onClick={() => {
                                setCryptoSelected(null);
                                setOpenModalCreateOrEdit(true);
                              }}
                            >
                              <PlusCircle className="h-3.5 w-3.5" />
                              <span
                                className="sr-only sm:not-sr-only sm:whitespace-nowrap"
                                //  onClick={() => navigate("/admin/analyses/create")}
                              >
                                Ajouter une crypto
                              </span>
                            </Button>
                          </DialogTrigger>
                        }
                      />
                      <Table>
                        <TableHeader>
                          <TableRow>
                            <TableHead className="hidden w-[100px] sm:table-cell">
                              <span className="sr-only">Image</span>
                            </TableHead>
                            <TableHead>Nom</TableHead>
                            <TableHead>Symbole</TableHead>
                            <TableHead>Date</TableHead>
                            <TableHead>
                              <span className="sr-only">Actions</span>
                            </TableHead>
                          </TableRow>
                        </TableHeader>
                        <TableBody>
                          {map(orderBy(cryptos, ["name"], ["asc"]), (c) => (
                            <TableRow key={c.cryptoId}>
                              <TableCell className="hidden sm:table-cell">
                                <img
                                  alt="Crypto image"
                                  className="aspect-square rounded-md object-cover"
                                  height="30"
                                  src={`/icons-cryptos/${c.symbol}.png`}
                                  width="30"
                                />
                              </TableCell>
                              <TableCell className="font-medium">
                                {c.name}
                              </TableCell>
                              <TableCell className="font-medium">
                                {c.symbol}
                              </TableCell>

                              <TableCell className="hidden md:table-cell">
                                {moment(c.createdAt).format("DD/MM/YYYY HH:mm")}
                              </TableCell>
                              <TableCell>
                                <div className="hidden items-center gap-3 sm:ml-auto sm:flex">
                                  <ModalAddEditCrypto
                                    openModal={openModalCreateOrEdit}
                                    closeModal={() => {
                                      setCryptoSelected(null);
                                      setOpenModalCreateOrEdit(false);
                                    }}
                                    cryptoSelected={cryptoSelected}
                                    dialogTrigger={
                                      <DialogTrigger asChild>
                                        <Button
                                          variant="default"
                                          size="icon"
                                          onClick={() => {
                                            setOpenModalCreateOrEdit(true);
                                            setCryptoSelected(c);
                                          }}
                                        >
                                          <Edit className="h-4 w-4" />
                                          <span className="sr-only">
                                            Modifier
                                          </span>
                                        </Button>
                                      </DialogTrigger>
                                    }
                                  />

                                  <AlertDialogTrigger asChild>
                                    <Button
                                      variant="destructive"
                                      size="icon"
                                      onClick={() => {
                                        setCryptoSelected(c);
                                      }}
                                    >
                                      <Trash className="h-4 w-4" />
                                      <span className="sr-only">Supprimer</span>
                                    </Button>
                                  </AlertDialogTrigger>
                                </div>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </CardContent>
                    <CardFooter>
                      <div className="text-xs text-muted-foreground">
                        <strong>
                          {(cryptos && values(cryptos).length) || 0}
                        </strong>{" "}
                        cryptos
                      </div>
                    </CardFooter>
                  </Card>
                </TabsContent>
              </Tabs>
            </main>
          </div>
        </div>
      </AlertDialog>
    </AdminLayout>
  );
};
export default CryptosPageAD;
