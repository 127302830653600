import { User } from "../types/User";
import { callApi } from "../store/api";

import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export async function uploadFile(file: FormData) {
  return callApi(`/uploads`, "POST", null, file, true).then((f) => f);
}

export async function deleteFile(filename: string) {
  return callApi(`/uploads/${filename}`, "DELETE", null);
}

export async function checkBingx(uid: string) {
  return callApi(`/exchanges/bingx/${uid}`, "GET", null).then((r) => {
    return r;
  });
}
export async function checkNotUsedUid(uid: string) {
  return callApi(`/users/checkNotUsedUid/${uid}`, "GET", null).then((r) => {
    return r;
  });
}

export function isAdmin(user: User) {
  if (user.roles) {
    return user.roles.includes("ADMIN");
  } else {
    return false;
  }
}
