import { createSlice, createAsyncThunk, ActionReducerMapBuilder } from '@reduxjs/toolkit';

import { schema, NormalizedSchema, normalize } from 'normalizr';

import { callApi } from '../api';
import { User } from '../../types/User';

const dataSchema = new schema.Entity('users', {}, { idAttribute: 'userId' });

type DataNormalized = NormalizedSchema<{ users: { [key: string]: User } }, string[]>;

interface State {
  data: DataNormalized['entities']['users'];
  status: 'idle' | 'loading' | 'failed';
  totalCount: number;
  totalPages: number;
}
const initialState: State = {
  data: {},
  status: 'idle',
  totalCount: 0,
  totalPages: 0,
};

export const getUsers = createAsyncThunk('getUsers', async (page: number) => {
  const response = await callApi(`/users/page/${page}`, 'GET', null);
  return response;
});

export const deleteUser = createAsyncThunk('deleteUser', async (user: User) => {
  const response = await callApi(`/users/${user.userId}`, 'DELETE', null, user);
  return response;
});

export const updateUser = createAsyncThunk(
  'updateUser',
  async ({ userId, user }: { userId: number; user: User }) => {
    const response = await callApi(`/users/${userId}`, 'PUT', null, user);

    return response;
  }
);

export const toogleActiveisAffiliated = createAsyncThunk(
  'toogleActiveisAffiliated',
  async (userId: number) => {
    const response = await callApi(`/users/toogleActiveisAffiliated/${userId}`, 'PUT', null);

    return response;
  }
);

const UsersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    // You can have other non-async actions here
  },
  extraReducers: (builder: ActionReducerMapBuilder<State>) => {
    builder
      /*------------------------------------------------
                          getUsers()
      --------------------------------------------------*/
      .addCase(getUsers.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getUsers.fulfilled, (state, action) => {
        const data = action.payload;

        state.status = 'idle';
        state.data =
          (data &&
            data.paginateData &&
            normalize(data.paginateData, [dataSchema]).entities.users) ||
          {};
        state.totalPages = (data && data.totalPages) || 0;
        state.totalCount = (data && data.totalCount) || 0;
      })
      .addCase(getUsers.rejected, (state, action) => {
        state.status = 'failed';
      })

      /* ------------------------------------------------
              updateUser()
  --------------------------------------------
  */
      .addCase(updateUser.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.status = 'idle';
        const updatedData = action.payload;
        state.data[updatedData.userId] = updatedData;
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.status = 'failed';
      })

      /* ------------------------------------------------
             toogleActiveisAffiliated()
--------------------------------------------
 */
      .addCase(toogleActiveisAffiliated.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(toogleActiveisAffiliated.fulfilled, (state, action) => {
        state.status = 'idle';
        const updatedData = action.payload;
        state.data[updatedData.userId] = updatedData;
      })
      .addCase(toogleActiveisAffiliated.rejected, (state, action) => {
        state.status = 'failed';
      })
      /* ------------------------------------------------
                    deleteUser()
     ------------------------------------------------- */
      .addCase(deleteUser.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        state.status = 'idle';

        const newData = { ...state.data };
        delete newData[action.payload.userId];
        state.data = newData;
      })
      .addCase(deleteUser.rejected, (state, action) => {
        state.status = 'failed';
      });
  },
});

export default UsersSlice.reducer;
