import { Modal, Button, ButtonGroup } from "react-bootstrap";

import { ClipLoader } from "react-spinners";

export type Props = {
  openModal: boolean;
  closeModal: () => void;
  actionConfirm: () => void;
  text: string;
  title: string;
  btnTextConfirm?: string;
  btnTextCancel?: string;
  loading: boolean;
};

const ModalConfim: React.FC<Props> = ({
  openModal,
  closeModal,
  actionConfirm,
  text,
  title,
  btnTextConfirm,
  btnTextCancel,
  loading,
}) => {
  return (
    <Modal
      show={openModal}
      onHide={() => {
        closeModal();
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{text}</p>
        <ButtonGroup className="mt-5" style={{ display: "flex" }}>
          <Button
            variant="primary"
            onClick={() => {
              closeModal();
            }}
          >
            {btnTextCancel || "Non"}
          </Button>
          <Button
            variant="primary"
            onClick={() => actionConfirm()}
            disabled={loading}
          >
            {loading ? (
              <ClipLoader color="white" loading size={20} />
            ) : (
              btnTextConfirm || "Oui"
            )}
          </Button>
        </ButtonGroup>
      </Modal.Body>
    </Modal>
  );
};

export default ModalConfim;
