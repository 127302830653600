import React from "react";

export type Props = {
  pageSelected: number;
  pageMax: number;
  onChangePage: (pageNumber: number) => void;
};

const Pagination: React.FC<Props> = ({
  pageSelected,
  pageMax,
  onChangePage,
}) => {
  const renderPageNumber = () => {
    const pageNumbers = [];
    for (let i = 0; i < pageMax; i++) {
      pageNumbers.push(
        <button
          type="button"
          aria-current="page"
          key={i}
          className={
            pageSelected === i + 1
              ? "min-h-[38px] min-w-[38px] flex justify-center items-center bg-gray-200 text-gray-800 py-2 px-3 text-sm rounded-lg focus:outline-none focus:bg-gray-300 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-600 dark:text-white dark:focus:bg-neutral-500"
              : "min-h-[38px] min-w-[38px] flex justify-center items-center text-gray-800 hover:bg-gray-100 py-2 px-3 text-sm rounded-lg focus:outline-none focus:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none dark:text-white dark:hover:bg-white/10 dark:focus:bg-white/10"
          }
          onClick={() => {
            onChangePage(i + 1);
            window.scrollTo({ top: 0, behavior: "smooth" });
          }}
        >
          {i + 1}
        </button>
      );
    }
    return pageNumbers;
  };
  return pageMax !== 0 ? (
    <nav className="flex justify-center items-center gap-x-1">
      <button
        type="button"
        disabled={pageSelected === 1}
        onClick={() => onChangePage(pageSelected - 1)}
        className="min-h-[38px] min-w-[38px] py-2 px-2.5 inline-flex jusify-center items-center gap-x-2 text-sm rounded-lg text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none dark:text-white dark:hover:bg-white/10 dark:focus:bg-white/10"
      >
        <svg
          className="flex-shrink-0 size-3.5"
          xmlns="http://www.w3.org/2000/svg"
          width={24}
          height={24}
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="m15 18-6-6 6-6" />
        </svg>
        <span aria-hidden="true" className="sr-only">
          Précédent
        </span>
      </button>
      <div className="flex items-center gap-x-1">{renderPageNumber()}</div>
      <button
        type="button"
        disabled={pageSelected === pageMax}
        onClick={() => onChangePage(pageSelected + 1)}
        className="min-h-[38px] min-w-[38px] py-2 px-2.5 inline-flex jusify-center items-center gap-x-2 text-sm rounded-lg text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none dark:text-white dark:hover:bg-white/10 dark:focus:bg-white/10"
      >
        <span aria-hidden="true" className="sr-only">
          Suivant
        </span>
        <svg
          className="flex-shrink-0 size-3.5"
          xmlns="http://www.w3.org/2000/svg"
          width={24}
          height={24}
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="m9 18 6-6-6-6" />
        </svg>
      </button>
    </nav>
  ) : null;
};

export default Pagination;
