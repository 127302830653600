import { useEffect } from 'react';
import { Link } from 'react-router-dom';

import { map, orderBy } from 'lodash';

import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store/configureStore';
import { getLatestVideos } from '../../store/modules/videos';

export default function Videos() {
  const dispatch = useDispatch<AppDispatch>();

  const videos = useSelector((state: RootState) => state.videos.data);

  useEffect(() => {
    dispatch(getLatestVideos());
  }, []);

  return (
    <div id='videos' className='max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto'>
      {/* Title */}
      <div className='max-w-2xl mx-auto text-center mb-10 lg:mb-14'>
        <h2 className='text-2xl font-bold md:text-4xl md:leading-tight dark:text-white'>
          Nos videos Youtube
        </h2>
        <p className='mt-1 text-gray-600 dark:text-gray-400'>
          Découvrez mes dernières vidéos publié sur Youtube
        </p>
      </div>

      <div className='grid sm:grid-cols-2 lg:grid-cols-3 gap-6'>
        {map(orderBy(videos, ['publishedAt'], ['desc']), (video) => {
          return (
            <Link
              key={video.videoId}
              target='_blank'
              className='group hover:bg-gray-100 rounded-xl p-5 transition-all dark:hover:bg-white/[.05]'
              to={`https://www.youtube.com/watch?v=${video.youtubeVideoId}&ab_channel=CRYPTOMAMOS`}
            >
              <div className='aspect-w-16 aspect-h-10'>
                <img
                  alt={video.title}
                  className='w-full object-cover rounded-xl'
                  src={video.thumbnail}
                />
              </div>
              <h3 className='mt-5 text-xl text-gray-800 dark:text-gray-300 dark:hover:text-white'>
                {video.title || ''}
              </h3>
            </Link>
          );
        })}
      </div>
    </div>
  );
}
