import { useEffect, useState, Fragment } from 'react';
import { Helmet } from 'react-helmet-async';

import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store/configureStore';

import { getIndicateurs } from '../../store/modules/indicateurs';

import { isEmpty, map, orderBy } from 'lodash';

import DefaultLayout from '../../components/layout/DefaultLayout';

import Pagination from '../../components/Pagination';
import CardIndicateur from '../../components/indicateurs/CardIndicateur';

const IndicateursPage = () => {
  const dispatch = useDispatch<AppDispatch>();

  const [actualPage, setActualPage] = useState<number>(1);

  const indicateurs = useSelector((state: RootState) => state.indicateurs.data);

  const maxPages = useSelector((state: RootState) => state.indicateurs.totalPages);

  useEffect(() => {
    dispatch(getIndicateurs(actualPage));
  }, [actualPage]);

  return (
    <Fragment>
      <Helmet>
        <title>Mamos Crypto [ Indicateurs ]</title>
      </Helmet>
      <DefaultLayout>
        <div className='max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto'>
          <div className='max-w-2xl mx-auto text-center mb-10 lg:mb-14'>
            <h2 className='text-2xl font-bold md:text-4xl md:leading-tight dark:text-white'>
              Indicateurs
            </h2>
            <p className='mt-1 text-gray-600 dark:text-neutral-400'>
              Mes indicateurs pour TradingView
            </p>
          </div>

          <div className='grid sm:grid-cols-2 lg:grid-cols-3 gap-6'>
            {map(orderBy(indicateurs, ['createdAt'], ['desc']), (indicateur) => (
              <CardIndicateur key={indicateur.indicateurId} indicateur={indicateur} />
            ))}
          </div>

          {indicateurs && !isEmpty(indicateurs) && (
            <Pagination
              pageSelected={actualPage}
              pageMax={maxPages}
              onChangePage={(p) => setActualPage(p)}
            />
          )}
        </div>
      </DefaultLayout>
    </Fragment>
  );
};
export default IndicateursPage;
