import { ReactNode, useState } from 'react';

import { CreateUpdateCryptoProps, Crypto } from '../../types/Crypto';
import { ApiError } from '../../types/Api';

import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../store/configureStore';
import { createCrypto, updateCrypto } from '../../store/modules/cryptos';

import { toLower } from 'lodash';

import { useForm } from 'react-hook-form';
import { AlertCircle } from 'lucide-react';
import { ClipLoader } from 'react-spinners';

import { Button } from '../ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogClose,
} from '../ui/dialog';
import { Input } from '../ui/input';
import { Label } from '../ui/label';
import { Alert, AlertDescription, AlertTitle } from '../ui/alert';

export type Props = {
  openModal: boolean;
  closeModal: () => void;
  cryptoSelected?: Crypto | null;
  dialogTrigger: ReactNode;
};

const ModalAddEditCrypto: React.FC<Props> = ({
  openModal,
  closeModal,
  cryptoSelected,
  dialogTrigger,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const { register, handleSubmit, reset } = useForm<CreateUpdateCryptoProps>({
    defaultValues: cryptoSelected || {},
    resetOptions: {
      keepDirtyValues: true,
    },
  });

  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const onSubmit = (data: CreateUpdateCryptoProps) => {
    data.symbol = toLower(data.symbol);

    setError(null);
    setLoading(true);

    if (cryptoSelected) {
      return dispatch(updateCrypto({ cryptoId: cryptoSelected.cryptoId, crypto: data }))
        .unwrap()
        .then(() => {
          reset();
          setLoading(false);
          closeModal();
        })
        .catch((e: ApiError) => {
          setLoading(false);
          setError(e.message);
        });
    } else {
      return dispatch(createCrypto(data))
        .unwrap()
        .then(() => {
          reset();
          setLoading(false);
          closeModal();
        })
        .catch((e: ApiError) => {
          setLoading(false);
          setError(e.message);
        });
    }
  };

  return (
    <Dialog>
      {dialogTrigger}
      {openModal && (
        <DialogContent className='sm:max-w-[425px]'>
          <form onSubmit={handleSubmit(onSubmit)}>
            <DialogHeader>
              <DialogTitle>{cryptoSelected ? 'Modifier' : 'Ajouter'} une crypto</DialogTitle>
              <DialogDescription>
                Make changes to your profile here. Click save when you're done.
              </DialogDescription>
            </DialogHeader>
            <div className='grid gap-4 py-4'>
              <div className='grid grid-cols-4 items-center gap-4'>
                <Label htmlFor='name' className='text-right'>
                  Nom
                </Label>
                <Input
                  id='name'
                  placeholder='Entrez le nom'
                  defaultValue={cryptoSelected?.name || ''}
                  {...register('name', { required: 'Nom requis' })}
                  className='col-span-3'
                />
              </div>
              <div className='grid grid-cols-4 items-center gap-4'>
                <Label htmlFor='username' className='text-right'>
                  Symbole
                </Label>
                <Input
                  id='symbol'
                  className='col-span-3'
                  placeholder='Entrez le symbole'
                  defaultValue={cryptoSelected?.symbol || ''}
                  {...register('symbol', { required: 'Symbole requis' })}
                />
              </div>
              {error && (
                <Alert variant='destructive'>
                  <AlertCircle className='h-4 w-4' />
                  <AlertTitle>Erreur</AlertTitle>
                  <AlertDescription>{error}</AlertDescription>
                </Alert>
              )}
            </div>
            <DialogFooter>
              <DialogClose
                asChild
                onClick={() => {
                  setError(null);
                  reset();
                }}
              >
                <Button variant='secondary'>Annuler</Button>
              </DialogClose>
              <Button type='submit'>
                {' '}
                {loading ? <ClipLoader color='white' loading size={20} /> : 'Enregistrer'}
              </Button>
            </DialogFooter>
          </form>
        </DialogContent>
      )}
    </Dialog>
  );
};

export default ModalAddEditCrypto;
