import { Fragment, useState } from 'react';
import { Helmet } from 'react-helmet-async';

import { Link, useNavigate } from 'react-router-dom';

import { LoginProps, User } from '../../types/User';
import { ApiError } from '../../types/Api';

import { AppDispatch } from '../../store/configureStore';
import { useDispatch } from 'react-redux';
import { loginUser } from '../../store/modules/auth';

import { useForm } from 'react-hook-form';

import { Button } from '../../components/ui/button';

import DefaultLayout from '../../components/layout/DefaultLayout';

const PasswordResetPage = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginProps>();
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const navigate = useNavigate();

  const dispatch = useDispatch<AppDispatch>();

  const onSubmit = (data: LoginProps) => {
    setError(null);
    setLoading(true);
    return dispatch(loginUser(data))
      .unwrap()
      .then((res: User) => {
        setLoading(false);
        if (res) {
          if (res.roles.includes('ADMIN')) {
            return navigate('/admin/analyses');
          }
          if (res.roles.includes('USER')) {
            return navigate('/analyses');
          }
        }
      })
      .catch((e: ApiError) => {
        setLoading(false);
        setError(e.message);
      });
  };

  return (
    <Fragment>
      <Helmet>
        <title>Mamos Crypto [ Mot de passe oublié ]</title>
      </Helmet>
      <DefaultLayout>
        <div className='flex justify-center'>
          <div className='w-full md:w-1/2 lg:w-1/4 px-3'>
            <div
              onClick={() => navigate('/auth/login')}
              className='text-center flex justify-center items-center text-gray-600 decoration-2 hover:underline dark:text-blue-500'
            >
              <svg
                className='flex-shrink-0 w-6 h-6 mr-1'
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 24 24'
                fill='none'
                stroke='currentColor'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
              >
                <path d='m15 18-6-6 6-6'></path>
              </svg>
              <span className='text-md text-gray-600 decoration-2 hover:underline dark:text-blue-500'>
                Retour
              </span>
            </div>

            <div className='mt-7 bg-dark border border-gray-200 rounded-xl shadow-sm dark:bg-dark-900 dark:border-neutral-700'>
              <div className='p-4 sm:p-7'>
                <div className='text-center'>
                  <h1 className='block text-2xl font-bold text-gray-800 dark:text-white'>
                    Mot de passe oublié ?
                  </h1>
                  <p className='mt-2 text-sm text-gray-600 dark:text-neutral-400'>
                    Souvenez-vous de votre mot de passe ? .
                    <Link
                      className='text-blue-600 decoration-2 hover:underline font-medium dark:text-blue-500'
                      to={'/auth/login'}
                    >
                      {' '}
                      Connectez-vous ici
                    </Link>
                  </p>
                </div>
                <div className='mt-5'>
                  {/* Form */}
                  <form>
                    <div className='grid gap-y-4'>
                      {/* Form Group */}
                      <div>
                        <label htmlFor='email' className='block text-sm mb-2 dark:text-white'>
                          Adresse e-mail
                        </label>
                        <div className='relative'>
                          <input
                            type='email'
                            id='email'
                            name='email'
                            className='py-3 px-4 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600'
                            required
                            aria-describedby='email-error'
                          />
                          <div className='hidden absolute inset-y-0 end-0 pointer-events-none pe-3'>
                            <svg
                              className='size-5 text-red-500'
                              width={16}
                              height={16}
                              fill='currentColor'
                              viewBox='0 0 16 16'
                              aria-hidden='true'
                            >
                              <path d='M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z' />
                            </svg>
                          </div>
                        </div>
                        <p className='hidden text-xs text-red-600 mt-2' id='email-error'>
                          Please include a valid email address so we can get back to you
                        </p>
                      </div>
                      {/* End Form Group */}
                      <Button type='submit' className='w-full'>
                        Réinitialiser mon mot de passe
                      </Button>
                    </div>
                  </form>
                  {/* End Form */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </DefaultLayout>
    </Fragment>
  );
};

export default PasswordResetPage;
