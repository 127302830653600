import { useState, Fragment } from "react";
import { Helmet } from "react-helmet-async";

import { Link } from "react-router-dom";

import { UpdateExchangeProps } from "../../types/User";
import { ApiError } from "../../types/Api";

import { isString, trim } from "lodash";

import { checkBingx, checkNotUsedUid } from "../../lib/utils";

import { AppDispatch, RootState } from "../../store/configureStore";
import { useDispatch, useSelector } from "react-redux";
import { updateExchange } from "../../store/modules/auth";

import { ClipLoader } from "react-spinners";
import { useForm } from "react-hook-form";

import DefaultLayout from "../../components/layout/DefaultLayout";
import AlertBloc from "../../components/AlertBloc";
import UpdateAvatarForm from "../../components/profil/UpdateAvatarForm";
import UpdatePasswordForm from "../../components/profil/UpdatePasswordForm";

const ProfilPage = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<UpdateExchangeProps>();

  const [errorValidateAffiliation, setErrorValidateAffiliation] = useState<
    string | null
  >(null);

  const [loadingUploadExchange, setLoadingUploadExchange] =
    useState<boolean>(false);

  const [isAffiliateValid, setIsAffiliateValid] = useState<boolean>(false);
  const [isAffiliateWaitVerif, setIsAffiliateWaitVerif] =
    useState<boolean>(false);

  const user = useSelector((state: RootState) => state.auth.user);

  const dispatch = useDispatch<AppDispatch>();

  const onSubmit = (data: any) => {
    if (user) {
      setIsAffiliateValid(false);
      setLoadingUploadExchange(true);
      setIsAffiliateWaitVerif(false);
      setErrorValidateAffiliation(null);

      if (data.exchange === "bingx") {
        return checkNotUsedUid(trim(data.uid))
          .then(() => {
            return checkBingx(trim(data.uid))
              .then(() => {
                setIsAffiliateValid(true);

                return dispatch(
                  updateExchange({
                    level: 1,
                    exchange: data.exchange,
                    uid: data.uid,
                    isAffiliated: true,
                  })
                );
              })
              .then(() => {
                setLoadingUploadExchange(false);
              })
              .catch((e: ApiError) => {
                setIsAffiliateValid(false);
                setLoadingUploadExchange(false);


                if (e && isString(e)) {
                  setErrorValidateAffiliation(e);
                } else {
                  setErrorValidateAffiliation("UID invalide");
                }
              });
          })
          .catch((e: string) => {
            setIsAffiliateValid(false);
            setLoadingUploadExchange(false);
            setErrorValidateAffiliation(e);
          });
      } else {
        // cas bitget
        return checkNotUsedUid(data.uid)
          .then(() => {
            return dispatch(
              updateExchange({
                level: 0,
                exchange: data.exchange,
                uid: data.uid,
                isAffiliated: false,
              })
            )
              .then(() => {
                setIsAffiliateWaitVerif(true);
                setLoadingUploadExchange(false);
              })
              .catch(() => setLoadingUploadExchange(false));
          })
          .catch((e: string) => {
            setLoadingUploadExchange(false);
            setErrorValidateAffiliation(e);
          });
      }
    }
  };

  return (
    <Fragment>
      <Helmet>
        <title>Mamos Crypto [ Mon profil ]</title>
      </Helmet>

      <DefaultLayout>
        <div className="max-w-4xl px-4 py-10 sm:px-6 lg:px-8 mx-auto">
          {/* Card */}
          <div className="bg-white rounded-xl shadow p-4 sm:p-7 dark:bg-neutral-900 mb-10">
            <div className="mb-8">
              <h2 className="text-xl font-bold text-gray-800 dark:text-neutral-200">
                Mon profil
              </h2>
              <p className="text-sm text-gray-600 dark:text-neutral-400">
                Editer mon profil
              </p>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              {/* Grid */}
              <div className="grid sm:grid-cols-12 gap-2 sm:gap-6">
                <UpdateAvatarForm />

                <div className="sm:col-span-3">
                  <label
                    htmlFor="af-account-email"
                    className="inline-block text-sm text-gray-800 mt-2.5 dark:text-neutral-200"
                  >
                    Pseudo
                  </label>
                </div>

                <div className="sm:col-span-9">
                  <input
                    id="af-account-email"
                    type="text"
                    disabled
                    defaultValue={(user && user.pseudo) || ""}
                    className="py-2 px-3 pe-11 block w-full border-gray-200 shadow-sm text-sm rounded-lg focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600"
                  />
                </div>

                <div className="sm:col-span-3">
                  <label
                    htmlFor="af-account-email"
                    className="inline-block text-sm text-gray-800 mt-2.5 dark:text-neutral-200"
                  >
                    E-mail
                  </label>
                </div>

                <div className="sm:col-span-9">
                  <input
                    id="af-account-email"
                    type="text"
                    disabled
                    defaultValue={(user && user.email) || ""}
                    className="py-2 px-3 pe-11 block w-full border-gray-200 shadow-sm text-sm rounded-lg focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600"
                  />
                </div>
                <div className="sm:col-span-3">
                  <label
                    htmlFor="af-account-email"
                    className="inline-block text-sm text-gray-800 mt-2.5 dark:text-neutral-200"
                  >
                    Compte activé
                  </label>
                </div>
                {/* End Col */}
                <div className="sm:col-span-9">
                  <div className="py-2 px-3 pe-11 block w-fullrounded-lg">
                    <span
                      className={`inline-flex items-center gap-x-1.5 py-1.5 px-3 rounded-full text-xs font-bold ${user && user.isAffiliated ? "bg-teal-500" : "bg-red-500"
                        } text-white text-bold`}
                    >
                      {user && user.isAffiliated
                        ? "Compte activé"
                        : "Compte non activé"}
                    </span>
                  </div>
                </div>
                <div className="sm:col-span-3">
                  <label
                    htmlFor="af-account-email"
                    className="inline-block text-sm text-gray-800 mt-2.5 dark:text-violet-500"
                  >
                    Exchange *
                  </label>
                </div>
                {/* End Col */}
                <div className="sm:col-span-9">
                  <select
                    {...register("exchange", { required: "exchange requis" })}
                    defaultValue={(user && user.exchange) || "bingx"}
                    className="py-3 px-4 pe-9 block w-full bg-gray-100 border-transparent rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-700 dark:border-transparent dark:text-neutral-400 dark:focus:ring-neutral-600"
                  >
                    <option value="bingx">Bingx</option>
                    {/* <option value='bitget'>Bitget</option> */}
                  </select>
                </div>
                <div className="sm:col-span-3">
                  <label
                    htmlFor="af-account-email"
                    className="inline-block text-sm text-gray-800 mt-2.5 dark:text-violet-500"
                  >
                    UID *
                  </label>
                </div>
                {/* End Col */}
                <div className="sm:col-span-9">
                  <input
                    id="af-account-email"
                    type="text"
                    {...register("uid", { required: "UID requis" })}
                    defaultValue={(user && user.uid) || ""}
                    className="py-2 px-3 pe-11 block w-full border-gray-200 shadow-sm text-sm rounded-lg focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600"
                  />
                  <Link
                    to="/affiliation"
                    className="mt-4 text-center inline-flex items-center align-middle text-md text-gray-600 decoration-2 hover:underline dark:text-violet-500"
                  >
                    📢 Activez mon compte grâce au parrainage
                  </Link>
                </div>
              </div>
              {/* End Grid */}
              {isAffiliateValid && (
                <AlertBloc
                  isError={false}
                  title="Affiliation validée"
                  text="Félicitations ! votre affiliation a bien été prise en
                      compte. Vous avez débloqué l'accès à nos analyses."
                />
              )}
              {isAffiliateWaitVerif && (
                <AlertBloc
                  isError={false}
                  isWarning={true}
                  title="Affiliation en attente de vérification"
                  text="Votre affiliation est en attente de vérification. Veuillez
                      patienter quelques jours pendant que notre équipe vérifie."
                />
              )}

              {errorValidateAffiliation && (
                <AlertBloc
                  isError={true}
                  title="Erreur de validation"
                  text={errorValidateAffiliation}
                />
              )}

              <div className="mt-5 flex justify-end gap-x-2">
                <button
                  disabled={loadingUploadExchange}
                  type="submit"
                  className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none"
                >
                  {loadingUploadExchange ? (
                    <ClipLoader color="white" loading size={20} />
                  ) : (
                    "Sauvegarder"
                  )}
                </button>
              </div>
            </form>
          </div>
          {/* End Card */}
          {/* Card */}
          <UpdatePasswordForm />
          {/* End Card */}
        </div>

        {/*     <div className='max-w-4xl px-4 py-10 sm:px-6 lg:px-8 mx-auto'>
        <div className='bg-white rounded-xl shadow p-4 sm:p-7 dark:bg-neutral-900'>
          <div className='mb-8'>
            <h2 className='text-xl font-bold text-gray-800 dark:text-neutral-200'>
              Mon mot de passe
            </h2>
            <p className='text-sm text-gray-600 dark:text-neutral-400'>Modifier mon mot de passe</p>
          </div>
          <form>
            <div className='grid sm:grid-cols-12 gap-2 sm:gap-6'>
              <div className='sm:col-span-3'>
                <label
                  htmlFor='af-account-password'
                  className='inline-block text-sm text-gray-800 mt-2.5 dark:text-neutral-200'
                >
                  Modifier mon mot de passe
                </label>
              </div>

              <div className='sm:col-span-9'>
                <div className='space-y-2'>
                  <input
                    id='af-account-password'
                    type='text'
                    className='py-2 px-3 pe-11 block w-full border-gray-200 shadow-sm rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600'
                    placeholder='Enter current password'
                  />
                  <input
                    type='text'
                    className='py-2 px-3 pe-11 block w-full border-gray-200 shadow-sm rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600'
                    placeholder='Enter new password'
                  />
                </div>
              </div>
            </div>

            <div className='mt-5 flex justify-end gap-x-2'>
              <button
                type='button'
                className='py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none'
              >
                Modifier
              </button>
            </div>
          </form>
        </div>
      </div> */}
      </DefaultLayout>
    </Fragment>
  );
};

export default ProfilPage;
