import React from "react";
import { Link } from "react-router-dom";

import { Analyse } from "../../types/Analyse";

import moment from "moment";

export type Props = {
  analyse: Analyse;
};

const CardAnalyse: React.FC<Props> = ({ analyse }) => {
  return (
    <Link
      className="group flex flex-col bg-white border shadow-sm rounded-xl hover:shadow-md transition dark:bg-slate-900 dark:border-gray-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
      to={`/analyse/${analyse.analyseId}`}
    >
      <div className="aspect-w-16 aspect-h-9" style={{ position: "relative" }}>
        <img
          className="w-full object-cover rounded-t-xl"
          src="jarvis.jpg"
          alt={`Analyse ${analyse.crypto.name || ""}`}
        />
        <img
          className="size-15 rounded-full"
          style={{ position: "absolute", top: -10, zIndex: 2000, right: -10 }}
          src={`/icons-cryptos/${
            (analyse && analyse.crypto && analyse.crypto.symbol) || ""
          }.png`}
          alt={`Analyse ${analyse.crypto.name || ""}`}
        />
      </div>
      <div className="p-4 md:p-5">
        <p className="mt-2 text-xs uppercase text-gray-600 dark:text-gray-400">
          {(analyse && analyse.crypto.name) || ""}
        </p>
        <h3 className="mt-2 text-lg font-medium text-gray-800 group-hover:text-blue-600 dark:text-gray-300 dark:group-hover:text-white">
          Analyse {(analyse && analyse.crypto.name) || ""} du{" "}
          {moment(analyse.date).format("DD/MM/YYYY")}
        </h3>
      </div>
    </Link>
  );
};

export default CardAnalyse;
