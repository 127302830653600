import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Analyse } from '../../types/Analyse';

import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store/configureStore';
import { getCryptos } from '../../store/modules/cryptos';
import { deleteAnalyse, getAnalyses } from '../../store/modules/analyses';

import { map, orderBy } from 'lodash';
import { Edit, Eye, PlusCircle, Trash } from 'lucide-react';
import moment from 'moment';

import { Button } from '../../components/ui/button';
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from '../../components/ui/card';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '../../components/ui/table';
import { Tabs, TabsContent } from '../../components/ui/tabs';
import AdminLayout from '../../components/layout/AdminLayout';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '../../components/ui/alert-dialog';
import Pagination from '../../components/Pagination';

const AnalysesPageAD = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const analyses = useSelector((state: RootState) => state.analyses.data);

  const [actualPage, setActualPage] = useState<number>(1);
  const maxPages = useSelector((state: RootState) => state.analyses.totalPages);

  const countAnalyses = useSelector((state: RootState) => state.analyses.totalCount);

  const [loadingDelete, setLoadingDelete] = useState<boolean>(false);
  const [analyseSelected, setAnalyseSelected] = useState<Analyse | null>(null);

  useEffect(() => {
    dispatch(getCryptos());
  }, []);

  useEffect(() => {
    dispatch(getAnalyses(actualPage));
  }, [actualPage]);

  const actionDelete = () => {
    setLoadingDelete(true);

    if (analyseSelected) {
      return dispatch(deleteAnalyse(analyseSelected))
        .unwrap()
        .then(() => {
          setLoadingDelete(false);
          setAnalyseSelected(null);
        })
        .catch(() => {
          setLoadingDelete(false);
        });
    }
  };

  return (
    <AdminLayout>
      <AlertDialog>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Supprimer cet analyse</AlertDialogTitle>
            <AlertDialogDescription>
              Confirmer vous la suppresion de cet analyse ?
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel
              onClick={() => {
                setAnalyseSelected(null);
              }}
            >
              Annuler
            </AlertDialogCancel>
            <AlertDialogAction disabled={loadingDelete} onClick={() => actionDelete()}>
              Oui
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>

        <div className='flex min-h-screen w-full flex-col'>
          <div className='flex flex-col sm:gap-4 sm:py-4 sm:pl-14'>
            <main className='grid flex-1 items-start gap-4 p-4 sm:px-6 sm:py-0 md:gap-8'>
              <Tabs defaultValue='all'>
                <TabsContent value='all'>
                  <Card>
                    <CardHeader>
                      <CardTitle>Analyses</CardTitle>
                    </CardHeader>

                    <CardContent>
                      <Button
                        size='sm'
                        className='h-8 gap-1 mb-4'
                        onClick={() => {
                          setAnalyseSelected(null);
                          navigate('/admin/analyses/create');
                        }}
                      >
                        <PlusCircle className='h-3.5 w-3.5' />
                        <span className='sr-only sm:not-sr-only sm:whitespace-nowrap'>
                          Ajouter une analyse
                        </span>
                      </Button>
                      <Table>
                        <TableHeader>
                          <TableRow>
                            <TableHead className='hidden w-[100px] sm:table-cell'>
                              <span className='sr-only'>Image</span>
                            </TableHead>
                            <TableHead>Crypto</TableHead>
                            <TableHead>Date</TableHead>
                            <TableHead>Likes</TableHead>
                            <TableHead>Commentaires</TableHead>
                            <TableHead>Note</TableHead>
                            <TableHead>
                              <span className='sr-only'>Actions</span>
                            </TableHead>
                          </TableRow>
                        </TableHeader>
                        <TableBody>
                          {map(orderBy(analyses, ['date'], ['desc']), (a) => (
                            <TableRow key={a.analyseId}>
                              <TableCell className='hidden sm:table-cell'>
                                <img
                                  alt='Crypto image'
                                  className='aspect-square rounded-md object-cover'
                                  height='30'
                                  src={`/icons-cryptos/${(a.crypto && a.crypto.symbol) || ''}.png`}
                                  width='30'
                                />
                              </TableCell>
                              <TableCell className='font-medium'>
                                {(a.crypto && a.crypto.name) || ''}
                              </TableCell>
                              <TableCell className='font-medium'>
                                {moment(a.date).format('DD/MM/YYYY')}
                              </TableCell>
                              <TableCell className='font-medium'>
                                {(a.likes && a.likes.length) || '0'}
                              </TableCell>
                              <TableCell className='font-medium'>
                                {(a.comments && a.comments.length) || 0}
                              </TableCell>
                              <TableCell className='font-medium'>{a.trustNote}/10</TableCell>

                              <TableCell>
                                <div className='hidden items-center gap-3 sm:ml-auto sm:flex'>
                                  <Button
                                    variant='outline'
                                    size='icon'
                                    onClick={() => {
                                      window.open(`/analyse/${a.analyseId}`, '_blank');
                                    }}
                                  >
                                    <Eye className='h-4 w-4' />
                                    <span className='sr-only'>Voir</span>
                                  </Button>
                                  <Button
                                    variant='default'
                                    size='icon'
                                    onClick={() => {
                                      navigate(`/admin/analyses/edit/${a.analyseId}`);
                                    }}
                                  >
                                    <Edit className='h-4 w-4' />
                                    <span className='sr-only'>Modifier</span>
                                  </Button>
                                  <AlertDialogTrigger asChild>
                                    <Button
                                      variant='destructive'
                                      size='icon'
                                      onClick={() => {
                                        setAnalyseSelected(a);
                                      }}
                                    >
                                      <Trash className='h-4 w-4' />
                                      <span className='sr-only'>Supprimer</span>
                                    </Button>
                                  </AlertDialogTrigger>
                                </div>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </CardContent>
                    <Pagination
                      pageSelected={actualPage}
                      pageMax={maxPages}
                      onChangePage={(p) => setActualPage(p)}
                    />
                    <CardFooter>
                      <div className='text-xs text-muted-foreground'>
                        <strong>{countAnalyses || 0}</strong> analyses
                      </div>
                    </CardFooter>
                  </Card>
                </TabsContent>
              </Tabs>
            </main>
          </div>
        </div>
      </AlertDialog>
    </AdminLayout>
  );
};
export default AnalysesPageAD;
