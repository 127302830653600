import {
  createSlice,
  createAsyncThunk,
  ActionReducerMapBuilder,
} from "@reduxjs/toolkit";

import axios from "axios";

import { omit } from "lodash";

import {
  LoginProps,
  RegisterProps,
  UpdateExchangeProps,
  User,
} from "../../types/User";

import API_URL from "../../constants/config";
import { callApi, setBearerToken } from "../api";

interface AuthState {
  user: User | null;
  token: string | null;

  status: "idle" | "loading" | "failed";
  error: null | string;
}

const initialState: AuthState = {
  user: null,
  token: null,
  status: "idle",
  error: null,
};

export const loginUser = createAsyncThunk(
  "loginUser",
  async (data: LoginProps) => {
    return axios
      .post(`${API_URL}/auth/login`, {
        email: data.email,
        password: data.password,
      })
      .then((res) => {
        const token = res.data.token;

        setBearerToken(token);
        return res.data;
      })
      .catch(function (error) {
        if (error.response) {
          throw String(error.response.data.message);
        } else if (error.request) {
          if (error.code === "ERR_NETWORK") {
            throw String("Impossible de se connecter au serveur.");
          }
        }
      });
  }
);

export const createUser = createAsyncThunk(
  "createUser",
  async (data: RegisterProps) => {
    const response = await callApi(`/auth/register`, "POST", null, data);
    return response;
  }
);

export const sendEmailSupport = createAsyncThunk(
  "sendEmailSupport",
  async (data: { objet: string; message: string }) => {
    const response = await callApi("/user/support", "POST", null, data);
    return response;
  }
);

export const updateNameFirstname = createAsyncThunk(
  "updateNameFirstname",
  async (data: { userId: number; prenom: string; nom: string }) => {
    const response = await callApi(
      `/user/${data.userId}`,
      "PUT",
      null,
      omit(data, ["userId"])
    );
    return response;
  }
);

export const updateAvatar = createAsyncThunk(
  "updateAvatar",
  async (avatar: string | null) => {
    const response = await callApi(`/users/avatar`, "PUT", null, { avatar });
    return response;
  }
);

export const updateExchange = createAsyncThunk(
  "updateExchange",
  async (data: UpdateExchangeProps) => {
    const response = await callApi(`/users/exchange`, "PUT", null, data);
    return response;
  }
);

export const updateUser = createAsyncThunk(
  "updateUser",
  async ({ userId, user }: { userId: number; user: User }) => {
    const response = await callApi(`/users/${userId}`, "PUT", null, user);

    return response;
  }
);

export const updateEmail = createAsyncThunk(
  "updateEmail",
  async (data: { userId: number; email: string }) => {
    const response = await callApi(
      `/user/${data.userId}`,
      "PUT",
      null,
      omit(data, ["userId"])
    );
    return response;
  }
);

export const loadUser = createAsyncThunk("loadUser", async () => {
  const response = await callApi(`/auth/me`, "GET", null);
  return response;
});

export const updatePassword = createAsyncThunk(
  "updatePassword",
  async (password: string) => {
    const response = await callApi(`/auth/password`, "PUT", null, {
      password,
    });
    return response;
  }
);

export const resetPassword = createAsyncThunk(
  "resetPassword",
  async (password: string) => {
    const response = await callApi(`/user/reset-password`, "PUT", null, {
      password,
    });
    return response;
  }
);
const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      state.user = null;
      state.token = null;
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<AuthState>) => {
    builder

      /* ------------------------------------------------
             updateUser()
--------------------------------------------
 */
      .addCase(updateUser.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.status = "idle";
        const updatedData = action.payload;
        state.user = updatedData;
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.status = "failed";
      })
      /* ------------------------------------------------
             updateAvatar()
--------------------------------------------
 */
      .addCase(updateAvatar.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateAvatar.fulfilled, (state, action) => {
        const updatedData = action.payload;

        let data = state.user;

        if (data && updatedData) {
          data.avatar = updatedData.avatar;
        }

        state.status = "idle";
        state.user = data;
      })
      .addCase(updateAvatar.rejected, (state, action) => {
        state.status = "failed";
      })
      /* ------------------------------------------------
             updateExchange()
--------------------------------------------
 */
      .addCase(updateExchange.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateExchange.fulfilled, (state, action) => {
        const updatedData = action.payload;

        let data = state.user;

        if (data && updatedData) {
          data.exchange = updatedData.exchange;
          data.level = updatedData.level || 0;
          data.uid = updatedData.uid;
          data.isAffiliated = updatedData.isAffiliated;
        }

        state.status = "idle";
        state.user = data;
      })
      .addCase(updateExchange.rejected, (state, action) => {
        state.status = "failed";
      })

      /*------------------------------------------------
                          loginUser()
      --------------------------------------------------*/
      .addCase(loginUser.pending, (state) => {
        state.status = "loading";
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.status = "idle";
        state.user = action.payload;
        state.token = action.payload.token;
        state.error = null;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || null;
      })
      /*------------------------------------------------
                          createUser)
      --------------------------------------------------*/
      .addCase(createUser.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createUser.fulfilled, (state, action) => {
        state.status = "idle";
        state.user = action.payload.user;
      })
      .addCase(createUser.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || null;
      })

      /*------------------------------------------------
                          updateEmail())
      --------------------------------------------------*/
      .addCase(updateEmail.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateEmail.fulfilled, (state, action) => {
        let data = state.user;

        if (data) {
          data.email = action.meta.arg.email;
        }

        state.status = "idle";
        state.user = data;
      })
      .addCase(updateEmail.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || null;
      })

      /*------------------------------------------------
                            loadUser())
        --------------------------------------------------*/

      .addCase(loadUser.pending, (state) => {
        state.status = "loading";
      })
      .addCase(loadUser.fulfilled, (state, action) => {
        state.status = "idle";
        state.user = action.payload;
      })
      .addCase(loadUser.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || null;
      });
  },
});

export const { logout } = authSlice.actions;

export default authSlice.reducer;
