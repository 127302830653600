import {
  configureStore,
  combineReducers,
  createAction,
  Reducer,
  Action,
} from "@reduxjs/toolkit";
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import logger from "redux-logger";

import authReducer from "./modules/auth";
import analysesReducer from "./modules/analyses";
import cryptosReducer from "./modules/cryptos";
import usersReducer from "./modules/users";
import commentsReducer from "./modules/comments";
import indicateursReducer from "./modules/indicateurs";
import videosReducer from "./modules/videos";

const persistConfig = {
  key: "jarvis",
  storage,
};

export type RootState = ReturnType<typeof combinedReducers> & {
  _persist: { version: number; rehydrated: boolean };
};

export const resetAllSlices = createAction("resetAllSlices");

const combinedReducers = combineReducers({
  auth: authReducer,
  analyses: analysesReducer,
  cryptos: cryptosReducer,
  users: usersReducer,
  comments: commentsReducer,
  indicateurs: indicateursReducer,
  videos: videosReducer,
});

const rootReducer: Reducer = (state: RootState | {}, action: Action) => {
  if (action.type === resetAllSlices.type) {
    persistConfig.storage.removeItem("persist:jarvis");
    state = {};
  }
  return combinedReducers(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        immutableCheck: false,
        serializableCheck: false,
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(process.env.NODE_ENV === "development" ? [logger] : []),
  devTools: process.env.NODE_ENV === "development",
});

export type AppDispatch = typeof store.dispatch;

export default store;
