import { ReactNode } from "react";

import { NavbarAD } from "../layout/NavbarAD";

interface DefaultLayoutProps {
  children: ReactNode;
}

const AdminLayout = ({ children }: DefaultLayoutProps) => {
  return (
    <div className="flex min-h-screen w-full flex-col dark">
      <NavbarAD />
      <main className="flex flex-1 flex-col gap-4 p-4 md:gap-8 md:p-8">
        {children}
      </main>
    </div>
  );
};

export default AdminLayout;
