import { NavLink } from 'react-router-dom';
import { Fragment } from 'react';
import { Helmet } from 'react-helmet-async';

import DefaultLayout from '../components/layout/DefaultLayout';

export default function ReservedPage() {
  return (
    <Fragment>
      <Helmet>
        <title>Jarvis - Mamos Crypto [ Page réservée ]</title>
      </Helmet>
      <DefaultLayout>
        <div className='overflow-hidden h-screen'>
          {/* Hero */}
          <div className='relative mt-20'>
            <div className='max-w-[85rem] px-4 pt-10 sm:px-6 lg:px-8 lg:pt-14 mx-auto flex flex-col justify-center items-center h-full'>
              {/* Title */}
              <div className='max-w-2xl mx-auto text-center mb-10'>
                <h2 className='text-3xl leading-tight font-bold md:text-4xl md:leading-tight lg:text-5xl lg:leading-tight bg-clip-text bg-gradient-to-r from-violet-600 to-fuchsia-700 text-transparent'>
                  Page réservée aux membres
                </h2>
                <p className='mt-2 lg:text-lg text-gray-800 dark:text-gray-200'>
                  Cette page est réservée aux membres. Inscris-toi gratuitement pour profiter de
                  toutes nos offres.
                </p>
              </div>

              {/* End Title */}

              <NavLink
                to='/auth/register'
                className='mt-5 py-3 px-4 inline-block justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-violet-600 text-white hover:bg-violet-700 disabled:opacity-50 disabled:pointer-events-none'
              >
                S'inscrire maintenant
              </NavLink>
              <NavLink
                to='/auth/login'
                className='mt-5 py-3 px-4  inline-block  justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-violet-600 text-violet-600 hover:border-violet-500 hover:text-violet-500 disabled:opacity-50 disabled:pointer-events-none dark:border-violet-500 dark:text-violet-500 dark:hover:text-violet-400 dark:hover:border-violet-400'
              >
                Se connecter
              </NavLink>
            </div>
            <div className='absolute top-1/2 start-1/2 -z-[1] transform -translate-y-1/2 -translate-x-1/2 w-[340px] h-[340px] border border-dashed border-violet-200 rounded-full dark:border-violet-900/60' />
            <div className='absolute top-1/2 start-1/2 -z-[1] transform -translate-y-1/2 -translate-x-1/2 w-[575px] h-[575px] border border-dashed border-violet-200 rounded-full opacity-80 dark:border-violet-900/60' />
            <div className='absolute top-1/2 start-1/2 -z-[1] transform -translate-y-1/2 -translate-x-1/2 w-[840px] h-[840px] border border-dashed border-violet-200 rounded-full opacity-60 dark:border-violet-900/60' />
            <div className='absolute top-1/2 start-1/2 -z-[1] transform -translate-y-1/2 -translate-x-1/2 w-[1080px] h-[1080px] border border-dashed border-violet-200 rounded-full opacity-40 dark:border-violet-900/60' />
          </div>
          {/* End Hero */}
        </div>

        {/* ========== END MAIN CONTENT ========== */}
        {/* ========== FOOTER ========== */}
      </DefaultLayout>
    </Fragment>
  );
}
