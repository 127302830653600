import {
  createSlice,
  createAsyncThunk,
  ActionReducerMapBuilder,
} from "@reduxjs/toolkit";

import { schema, NormalizedSchema } from "normalizr";

import { callApi } from "../api";
import { CreateUpdateCryptoProps, Crypto } from "../../types/Crypto";

const dataSchema = new schema.Entity(
  "cryptos",
  {},
  { idAttribute: "cryptoId" }
);

type DataNormalized = NormalizedSchema<
  { cryptos: { [key: string]: Crypto } },
  string[]
>;

interface State {
  data: DataNormalized["entities"]["cryptos"];
  status: "idle" | "loading" | "failed";
}
const initialState: State = {
  data: {},
  status: "idle",
};

export const getCryptos = createAsyncThunk("getCryptos", async () => {
  const response = await callApi("/cryptos", "GET", dataSchema);
  return response;
});

export const createCrypto = createAsyncThunk(
  "createCrypto",
  async (data: CreateUpdateCryptoProps) => {
    const response = await callApi("/cryptos", "POST", null, data);
    return response;
  }
);

export const deleteCrypto = createAsyncThunk(
  "deleteCrypto",
  async (crypto: Crypto) => {
    const response = await callApi(
      `/cryptos/${crypto.cryptoId}`,
      "DELETE",
      null,
      crypto
    );
    return response;
  }
);

export const updateCrypto = createAsyncThunk(
  "updateCrypto",
  async ({
    cryptoId,
    crypto,
  }: {
    cryptoId: number;
    crypto: CreateUpdateCryptoProps;
  }) => {
    const response = await callApi(`/cryptos/${cryptoId}`, "PUT", null, crypto);

    return response;
  }
);

const CryptosSlice = createSlice({
  name: "cryptos",
  initialState,
  reducers: {
    // You can have other non-async actions here
  },
  extraReducers: (builder: ActionReducerMapBuilder<State>) => {
    builder
      /*------------------------------------------------
                          getCryptos()
      --------------------------------------------------*/
      .addCase(getCryptos.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getCryptos.fulfilled, (state, action) => {
        state.status = "idle";
        state.data = action.payload?.entities?.cryptos || {};
      })
      .addCase(getCryptos.rejected, (state, action) => {
        state.status = "failed";
      })
      /*------------------------------------------------
                          createCrypto()
      --------------------------------------------------*/
      .addCase(createCrypto.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createCrypto.fulfilled, (state, action) => {
        state.status = "idle";
        state.data = {
          ...state.data,
          [action.payload.cryptoId]: action.payload,
        };
      })
      .addCase(createCrypto.rejected, (state, action) => {
        state.status = "failed";
      })
      /* ------------------------------------------------
             updateCrypto()
--------------------------------------------
 */
      .addCase(updateCrypto.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateCrypto.fulfilled, (state, action) => {
        state.status = "idle";
        const updatedData = action.payload;
        state.data[updatedData.cryptoId] = updatedData;
      })
      .addCase(updateCrypto.rejected, (state, action) => {
        state.status = "failed";
      })
      /* ------------------------------------------------
                    deleteCrypto()
     ------------------------------------------------- */
      .addCase(deleteCrypto.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteCrypto.fulfilled, (state, action) => {
        state.status = "idle";

        const newData = { ...state.data };
        delete newData[action.payload.cryptoId];
        state.data = newData;
      })
      .addCase(deleteCrypto.rejected, (state, action) => {
        state.status = "failed";
      });
  },
});

export default CryptosSlice.reducer;
