import { Fragment, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import { ApiError } from '../../types/Api';

import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store/configureStore';
import { getIndicateur } from '../../store/modules/indicateurs';

import DefaultLayout from '../../components/layout/DefaultLayout';

const IndicateurPage = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { indicateurId } = useParams();

  const navigation = useNavigate();

  const indicateur = useSelector(
    (state: RootState) => state.indicateurs.data[Number(indicateurId)]
  );

  useEffect(() => {
    if (indicateurId) {
      dispatch(getIndicateur(Number(indicateurId)))
        .unwrap()
        .catch((e: ApiError) => {
          if (e.message && e.message === 'Cet indicateur existe pas') {
            navigation('/404');
          }
        });
    }
  }, []);

  return (
    <Fragment>
      <Helmet>
        <title>Mamos Crypto [{`Indicateur ${(indicateur && indicateur.name) || ''} }`}]</title>
      </Helmet>
      <DefaultLayout>
        {/* Blog Article */}
        <div className='max-w-3xl px-4 pt-6 lg:pt-10 pb-12 sm:px-6 lg:px-8 mx-auto'>
          <div className='max-w-2xl'>
            <div
              onClick={() => navigation('/indicateurs')}
              className='mb-7 text-center inline-flex items-center align-middle text-lg text-gray-600 decoration-2 hover:underline dark:text-blue-500'
            >
              <svg
                className='flex-shrink-0 size-4'
                xmlns='http://www.w3.org/2000/svg'
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
                stroke='currentColor'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
              >
                <path d='m15 18-6-6 6-6'></path>
              </svg>
              Retour aux indicateurs
            </div>

            {/* Content */}
            <div className='space-y-5 md:space-y-8'>
              <div className='space-y-3'>
                <h2 className='text-2xl font-bold md:text-3xl dark:text-white'>
                  Indicateur {(indicateur && indicateur.name) || ''}
                </h2>
              </div>

              {indicateur && (
                <div
                  className='space-y-3'
                  dangerouslySetInnerHTML={{ __html: indicateur.description }}
                />
              )}
            </div>
            {/* End Content */}
          </div>
        </div>
      </DefaultLayout>
    </Fragment>
  );
};
export default IndicateurPage;
