import {
  createSlice,
  createAsyncThunk,
  ActionReducerMapBuilder,
} from "@reduxjs/toolkit";

import { schema, NormalizedSchema } from "normalizr";

import { callApi } from "../api";
import { Video } from "../../types/Video";

const dataSchema = new schema.Entity("videos", {}, { idAttribute: "videoId" });

type DataNormalized = NormalizedSchema<
  { videos: { [key: string]: Video } },
  string[]
>;

interface State {
  data: DataNormalized["entities"]["videos"];
  status: "idle" | "loading" | "failed";
}
const initialState: State = {
  data: {},
  status: "idle",
};

export const getLatestVideos = createAsyncThunk("getLatestVideos", async () => {
  const response = await callApi("/videos", "GET", dataSchema);
  return response;
});

const VideosSlice = createSlice({
  name: "videos",
  initialState,
  reducers: {
    // You can have other non-async actions here
  },
  extraReducers: (builder: ActionReducerMapBuilder<State>) => {
    builder
      /*------------------------------------------------
                          getLatestVideos()
      --------------------------------------------------*/
      .addCase(getLatestVideos.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getLatestVideos.fulfilled, (state, action) => {
        state.status = "idle";
        state.data = action.payload?.entities?.videos || {};
      })
      .addCase(getLatestVideos.rejected, (state, action) => {
        state.status = "failed";
      });
  },
});

export default VideosSlice.reducer;
