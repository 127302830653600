import { createSlice, createAsyncThunk, ActionReducerMapBuilder } from '@reduxjs/toolkit';

import { schema, NormalizedSchema, normalize } from 'normalizr';

import { callApi } from '../api';

import { Indicateur, CreateUpdateIndicateurProps } from '../../types/Indicateur';

const dataSchema = new schema.Entity('indicateurs', {}, { idAttribute: 'indicateurId' });

type DataNormalized = NormalizedSchema<{ indicateurs: { [key: string]: Indicateur } }, string[]>;

interface State {
  data: DataNormalized['entities']['indicateurs'];
  status: 'idle' | 'loading' | 'failed';
  totalCount: number;
  totalPages: number;
}
const initialState: State = {
  data: {},
  status: 'idle',
  totalCount: 0,
  totalPages: 0,
};

export const getIndicateurs = createAsyncThunk('getIndicateurs', async (page: number) => {
  const response = await callApi(`/indicateurs/page/${page}`, 'GET', null);

  return response;
});

export const getIndicateur = createAsyncThunk('getIndicateur', async (indicateurId: number) => {
  const response = await callApi(`/indicateurs/${indicateurId}`, 'GET', null);
  return response;
});

export const createIndicateur = createAsyncThunk(
  'createIndicateur',
  async (data: CreateUpdateIndicateurProps) => {
    const response = await callApi('/indicateurs', 'POST', null, data);
    return response;
  }
);

export const deleteIndicateur = createAsyncThunk(
  'deleteIndicateur',
  async (indicateur: Indicateur) => {
    const response = await callApi(
      `/indicateurs/${indicateur.indicateurId}`,
      'DELETE',
      null,
      indicateur
    );
    return response;
  }
);

export const updateIndicateur = createAsyncThunk(
  'updateIndicateur',
  async ({
    indicateurId,
    indicateur,
  }: {
    indicateurId: number;
    indicateur: CreateUpdateIndicateurProps;
  }) => {
    const response = await callApi(`/indicateurs/${indicateurId}`, 'PUT', null, indicateur);

    return response;
  }
);

const IndicateursSlice = createSlice({
  name: 'indicateurs',
  initialState,
  reducers: {
    // You can have other non-async actions here
  },
  extraReducers: (builder: ActionReducerMapBuilder<State>) => {
    builder

      /*------------------------------------------------
                          getIndicateur()
      --------------------------------------------------*/
      .addCase(getIndicateur.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getIndicateur.fulfilled, (state, action) => {
        state.status = 'idle';
        const indicateurId = action.meta.arg;

        state.data = {
          ...state.data,
          [indicateurId]: action.payload,
        };
      })
      .addCase(getIndicateur.rejected, (state, action) => {
        state.status = 'failed';
      })

      /*------------------------------------------------
                          getIndicateurs()
      --------------------------------------------------*/
      .addCase(getIndicateurs.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getIndicateurs.fulfilled, (state, action) => {
        const data = action.payload;

        state.status = 'idle';
        state.data =
          (data &&
            data.paginateData &&
            normalize(data.paginateData, [dataSchema]).entities.indicateurs) ||
          {};
        state.totalPages = (data && data.totalPages) || 0;
        state.totalCount = (data && data.totalCount) || 0;
      })
      .addCase(getIndicateurs.rejected, (state, action) => {
        state.status = 'failed';
      })
      /*------------------------------------------------
                          createIndicateur()
      --------------------------------------------------*/
      .addCase(createIndicateur.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createIndicateur.fulfilled, (state, action) => {
        state.status = 'idle';
        state.data = {
          ...state.data,
          [action.payload.indicateurId]: action.payload,
        };
      })
      .addCase(createIndicateur.rejected, (state, action) => {
        state.status = 'failed';
      })

      /* ------------------------------------------------
             updateIndicateur()
--------------------------------------------
 */
      .addCase(updateIndicateur.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateIndicateur.fulfilled, (state, action) => {
        state.status = 'idle';
        const updatedData = action.payload;
        state.data[updatedData.indicateurId] = updatedData;
      })
      .addCase(updateIndicateur.rejected, (state, action) => {
        state.status = 'failed';
      })

      /* ------------------------------------------------
                    deleteIndicateur()
     ------------------------------------------------- */
      .addCase(deleteIndicateur.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteIndicateur.fulfilled, (state, action) => {
        state.status = 'idle';

        const newData = { ...state.data };
        delete newData[action.payload.indicateurId];
        state.data = newData;
      })
      .addCase(deleteIndicateur.rejected, (state, action) => {
        state.status = 'failed';
      });
  },
});

export default IndicateursSlice.reducer;
