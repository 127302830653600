import { useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { CreateUpdateAnalyseProps, Analyse } from '../../types/Analyse';
import { ApiError } from '../../types/Api';

import API_URL from '../../constants/config';

import { map } from 'lodash';

import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import { deleteFile, uploadFile } from '../../lib/utils';
import moment from 'moment';

import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store/configureStore';
import { updateAnalyse, createAnalyse, deleteAnalyse } from '../../store/modules/analyses';

import { ClipLoader } from 'react-spinners';
import { ChevronLeft, AlertCircle, Upload } from 'lucide-react';

import { Badge } from '../../components/ui/badge';
import { Button } from '../../components/ui/button';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '../../components/ui/card';
import { Input } from '../../components/ui/input';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../../components/ui/select';
import { Trash } from 'lucide-react';
import { Alert, AlertDescription, AlertTitle } from '../../components/ui/alert';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '../../components/ui/alert-dialog';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '../../components/ui/form';
import { Textarea } from '../../components/ui/textarea';
import AdminLayout from '../../components/layout/AdminLayout';

const CreateUpdateAnalysePageAD: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const fileInputRef = useRef(null);

  const { analyseId } = useParams();
  const [loadingDelete, setLoadingDelete] = useState<boolean>(false);
  const [loadingUploadImage, setLoadingUploadImage] = useState<boolean>(false);
  const [loadingDeleteImage, setLoadingDeleteImage] = useState<boolean>(false);

  const [imageName, setImageName] = useState<string | null>(null);

  const cryptos = useSelector((state: RootState) => state.cryptos.data);
  const analyses = useSelector((state: RootState) => state.analyses.data);

  let analyse: Analyse | undefined;
  if (analyseId) {
    analyse = analyses[analyseId];
  }

  const FormSchema = z.object({
    trustNote: z.string({
      required_error: 'Note requis',
    }),
    texte: z.string({
      required_error: 'Texte requis',
    }),
    date: z.string({
      required_error: 'A date of birth is required.',
    }),
    // image: z.string() || z.null(),
    cryptoId: z.string({
      required_error: 'Crypto requis',
    }),
  });

  const form = useForm<CreateUpdateAnalyseProps>({
    resolver: zodResolver(FormSchema),

    defaultValues: (analyse && {
      ...analyse,
      trustNote: String(analyse.trustNote),
      cryptoId: String(analyse.cryptoId),
    }) || {
      date: moment().format('DD/MM/YYYY'),
    },
  });

  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const navigate = useNavigate();
  const cancel = () => {
    form.reset();
    return navigate('/admin/analyses');
  };

  const handleImageInputChange = (e: any) => {
    const file = e.target.files[0];

    const formData = new FormData();
    formData.append('file', file);

    if (formData) {
      setLoadingUploadImage(true);

      return uploadFile(formData)
        .then((fileName) => {
          // set form
          form.setValue('image', fileName);
          setImageName(fileName);
          setLoadingUploadImage(false);
        })
        .catch(() => {
          setLoadingUploadImage(false);
        });
    }
  };

  function onSubmit(data: CreateUpdateAnalyseProps) {
    setError(null);
    setLoading(true);

    if (analyse) {
      data.image = imageName;
      return dispatch(
        updateAnalyse({
          analyseId: analyse.analyseId,
          analyse: data,
        })
      )
        .unwrap()
        .then(() => {
          form.reset();
          setLoading(false);
          return navigate('/admin/analyses');
        })
        .catch((e: ApiError) => {
          setLoading(false);
          setError(e.message);
        });
    } else {
      data.image = imageName;
      return dispatch(createAnalyse(data))
        .unwrap()
        .then(() => {
          form.reset();
          setLoading(false);
          return navigate('/admin/analyses');
        })
        .catch((e: ApiError) => {
          setLoading(false);
          setError(e.message);
        });
    }
  }

  const deleteImageAnalyse = (filename: string | null | undefined) => {
    setLoadingDeleteImage(true);

    setImageName(null);
    form.setValue('image', null);

    if (filename) {
      return deleteFile(filename)
        .then(() => {
          if (analyse && analyse.image) {
            let newData = analyse;

            newData.image = null;

            return dispatch(
              updateAnalyse({
                analyseId: analyse.analyseId,
                analyse: newData,
              })
            );
          }
        })
        .then(() => {
          setLoadingDeleteImage(false);
        })
        .catch(() => {
          setLoadingDeleteImage(false);
        });
    }
  };

  const supprimerAnalyse = () => {
    setError(null);
    setLoadingDelete(true);

    if (analyse) {
      if (analyse && analyse.image) {
        setImageName(null);
        return deleteFile(analyse.image).then(() => {
          if (analyse) {
            return dispatch(deleteAnalyse(analyse))
              .unwrap()
              .then(() => {})
              .then(() => {
                form.reset();
                setLoadingDelete(true);
                return navigate('/admin/analyses');
              })
              .catch((e: ApiError) => {
                setLoadingDelete(true);
                setError(e.message);
              });
          }
        });
      } else {
        return dispatch(deleteAnalyse(analyse))
          .unwrap()
          .then(() => {})
          .then(() => {
            form.reset();
            setLoadingDelete(true);
            return navigate('/admin/analyses');
          })
          .catch((e: ApiError) => {
            setLoadingDelete(true);
            setError(e.message);
          });
      }
    }
  };

  return (
    <AdminLayout>
      <AlertDialog>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Supprimer cet analyse</AlertDialogTitle>
            <AlertDialogDescription>
              Confirmer vous la suppresion de cet analyse ?
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Annuler</AlertDialogCancel>
            <AlertDialogAction disabled={loadingDelete} onClick={() => supprimerAnalyse()}>
              Oui
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
        <div className='flex min-h-screen w-full flex-col'>
          <div className='flex flex-col sm:gap-4 sm:py-4 sm:pl-14'>
            <main className='grid flex-1 items-start gap-4 p-4 sm:px-6 sm:py-0 md:gap-8'>
              <Form {...form}>
                <form
                  onSubmit={form.handleSubmit(onSubmit)}
                  className='mx-auto grid max-w-[59rem] flex-1 auto-rows-max gap-4'
                >
                  <div className='flex items-center gap-4'>
                    <Button
                      variant='outline'
                      size='icon'
                      className='h-7 w-7'
                      onClick={() => cancel()}
                    >
                      <ChevronLeft className='h-4 w-4' />
                      <span className='sr-only'>Back</span>
                    </Button>

                    <h1 className='flex-1 shrink-0 whitespace-nowrap text-xl font-semibold tracking-tight sm:grow-0'>
                      {(analyse && 'Modifier') || 'Ajouter'} une analyse
                    </h1>

                    <Badge variant='default' className='ml-auto sm:ml-0'>
                      Publiée
                    </Badge>
                    <div className='hidden items-center gap-2 md:ml-auto md:flex'>
                      {analyse && (
                        <AlertDialogTrigger asChild>
                          <Button size='sm' variant='destructive'>
                            Supprimer
                          </Button>
                        </AlertDialogTrigger>
                      )}

                      <Button type='button' onClick={() => cancel()} variant='outline' size='sm'>
                        Annuler
                      </Button>

                      <Button type='submit' size='sm' disabled={loading}>
                        {loading ? <ClipLoader color='white' loading size={20} /> : 'Enregistrer'}
                      </Button>
                    </div>
                  </div>
                  {error && (
                    <Alert variant='destructive'>
                      <AlertCircle className='h-4 w-4' />
                      <AlertTitle>Erreur</AlertTitle>
                      <AlertDescription>{error}</AlertDescription>
                    </Alert>
                  )}

                  <div className='grid gap-4 md:grid-cols-[1fr_250px] lg:grid-cols-3 lg:gap-8'>
                    <div className='grid auto-rows-max items-start gap-4 lg:col-span-2 lg:gap-8'>
                      <Card>
                        <CardHeader>
                          <CardTitle>Mon analyse</CardTitle>
                        </CardHeader>
                        <CardContent>
                          <div className='grid gap-6'>
                            <div className='grid gap-3'>
                              <FormField
                                control={form.control}
                                name='trustNote'
                                render={({ field }) => (
                                  <FormItem>
                                    <FormLabel>Note de confiance *</FormLabel>
                                    <FormControl>
                                      <Input
                                        {...field}
                                        type='number'
                                        placeholder='Entrez une note de confiance'
                                        defaultValue={
                                          (analyse &&
                                            analyse.trustNote &&
                                            String(analyse.trustNote)) ||
                                          undefined
                                        }
                                        value={field.value}
                                      />
                                    </FormControl>
                                    <FormMessage />
                                  </FormItem>
                                )}
                              />
                            </div>
                            <div className='grid gap-3'>
                              <FormField
                                control={form.control}
                                name='texte'
                                render={({ field }) => (
                                  <FormItem>
                                    <FormLabel>Texte *</FormLabel>
                                    <FormControl {...field}>
                                      <Textarea
                                        className='min-h-32'
                                        placeholder='Entrez le texte'
                                        value={field.value}
                                      />
                                    </FormControl>
                                    <FormMessage />
                                  </FormItem>
                                )}
                              />
                            </div>
                          </div>
                        </CardContent>
                      </Card>
                    </div>
                    <div className='grid auto-rows-max items-start gap-4 lg:gap-8'>
                      <Card>
                        <CardHeader></CardHeader>
                        <CardContent>
                          <div className='grid gap-6'>
                            <div className='grid gap-3'>
                              <FormField
                                control={form.control}
                                name='date'
                                render={({ field }) => (
                                  <FormField
                                    control={form.control}
                                    name='date'
                                    render={({ field }) => (
                                      <FormItem>
                                        <FormLabel>Date *</FormLabel>
                                        <FormControl
                                          {...field}
                                          defaultValue={moment().format('DD/MM/YYYY')}
                                        >
                                          <Input
                                            type='date'
                                            placeholder='Entrez une note de confiance'
                                            value={field.value}
                                          />
                                        </FormControl>
                                        <FormMessage />
                                      </FormItem>
                                    )}
                                  />
                                )}
                              />
                              <FormField
                                control={form.control}
                                name='cryptoId'
                                render={({ field }) => (
                                  <FormItem>
                                    <FormLabel>Crypto *</FormLabel>
                                    <Select
                                      onValueChange={field.onChange}
                                      defaultValue={
                                        (analyse && String(analyse.cryptoId)) || undefined
                                      }
                                    >
                                      <FormControl>
                                        <SelectTrigger>
                                          <SelectValue placeholder='Selectionner une crypto' />
                                        </SelectTrigger>
                                      </FormControl>
                                      <SelectContent>
                                        {map(cryptos, (c) => (
                                          <SelectItem key={c.cryptoId} value={String(c.cryptoId)}>
                                            {c.name}
                                          </SelectItem>
                                        ))}
                                      </SelectContent>
                                    </Select>

                                    <FormMessage />
                                  </FormItem>
                                )}
                              />
                            </div>
                          </div>
                        </CardContent>
                      </Card>

                      <Card className='overflow-hidden'>
                        <CardHeader>
                          <CardTitle>Image</CardTitle>
                          <CardDescription>Image de couverture de l'analyse</CardDescription>
                        </CardHeader>
                        <CardContent>
                          <FormField
                            control={form.control}
                            name='image'
                            render={({ field }) => (
                              <div className='grid gap-2'>
                                <input
                                  ref={fileInputRef}
                                  type='file'
                                  accept='image/*'
                                  onChange={handleImageInputChange}
                                  style={{ display: 'none' }}
                                />

                                {field && field.value ? (
                                  <div style={{ position: 'relative' }}>
                                    <button
                                      style={{ position: 'absolute', top: -15, right: -20 }}
                                      onClick={() => {
                                        deleteImageAnalyse(field.value);
                                      }}
                                      disabled={loadingDeleteImage}
                                      type='button'
                                      className='flex items-center px-3 py-1 bg-red-500 text-white rounded-md hover:bg-red-600 focus:outline-none'
                                    >
                                      <Trash />
                                    </button>
                                    <img
                                      alt='Product image'
                                      className='aspect-square w-full rounded-md object-cover'
                                      height='300'
                                      src={`${API_URL}/uploads/${field.value}`}
                                      width='300'
                                    />
                                  </div>
                                ) : (
                                  <div className='grid grid-cols-3 gap-2'>
                                    <button
                                      type='button'
                                      onClick={() => {
                                        if (fileInputRef.current) {
                                          (fileInputRef.current as HTMLInputElement).click();
                                        }
                                      }}
                                      disabled={loadingUploadImage}
                                      className='flex aspect-square w-full items-center justify-center rounded-md border border-dashed'
                                    >
                                      <Upload className='h-4 w-4 text-muted-foreground' />
                                      <span className='sr-only'>Upload</span>
                                    </button>
                                  </div>
                                )}
                              </div>
                            )}
                          />
                        </CardContent>
                      </Card>
                    </div>
                  </div>
                  <div className='flex items-center justify-center gap-2 md:hidden'>
                    {analyse && (
                      <AlertDialogTrigger asChild>
                        <Button size='sm' variant='destructive'>
                          Supprimer
                        </Button>
                      </AlertDialogTrigger>
                    )}

                    <Button type='button' variant='outline' size='sm' onClick={() => cancel()}>
                      Annuler
                    </Button>

                    <Button size='sm' disabled={loading} type='submit'>
                      {loading ? <ClipLoader color='white' loading size={20} /> : 'Enregistrer'}
                    </Button>
                  </div>
                </form>
              </Form>
            </main>
          </div>
        </div>
      </AlertDialog>
    </AdminLayout>
  );
};
export default CreateUpdateAnalysePageAD;
