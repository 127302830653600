import { Fragment } from 'react';
import { Helmet } from 'react-helmet-async';

import { Link } from 'react-router-dom';
import DefaultLayout from '../components/layout/DefaultLayout';

export default function AffiliationExplikPage() {
  return (
    <Fragment>
      <Helmet>
        <title>Mamos Crypto [ Affiliation ]</title>
      </Helmet>
      <DefaultLayout>
        <div className='overflow-hidden h-screen'>
          {/* Hero */}
          <div className='mt-20'>
            <div className='max-w-[85rem] px-4 sm:px-6 lg:px-8 lg:pt-14 mx-auto flex flex-col justify-center items-center h-full'>
              {/* Title */}
              <div className='max-w-2xl mx-auto text-center mb-10'>
                <h2 className='text-3xl leading-tight font-bold md:text-4xl md:leading-tight lg:text-5xl lg:leading-tight bg-clip-text bg-gradient-to-r from-violet-600 to-fuchsia-700 text-transparent'>
                  Activer mon compte
                </h2>
                <p className='mt-2 lg:text-lg text-gray-800 dark:text-gray-200 mb-4'>
                  <ul>
                    <li></li>
                  </ul>
                  {/*   📢 L'accès aux analyses de Jarvis est gratuit. Cependant, pour éviter de vous faire
                payer, nous utilisons l'affiliation via les échanges BITGET ou BINGX. <br /> <br />{' '}
                ✅ Pour valider votre compte, créez un compte sur l'une de ces plateformes <br />{' '}
                via nos liens de parrainage. */}
                  📢 L'accès aux analyses de Mamos Crypto est gratuit. Cependant, pour éviter de
                  vous faire payer, nous utilisons l'affiliation via l'échange BINGX. <br /> <br />{' '}
                  ✅ Pour valider votre compte, créez un compte sur la plateforme BINGX <br /> via
                  notre lien de parrainage.
                  <br />
                  <br />
                  <a
                    target='_blank'
                    href='https://bingx.com/fr-fr/partner/CryptoMamos'
                    className='text-violet-600'
                  >
                    🔗 Activer mon compte via BingX
                  </a>
                  <div className='pb-0.1' />
                  {/*    <br />
                <a
                  target='_blank'
                  href='https://bonus.bitget.com/Mamos'
                  className='text-violet-600'
                >
                  🔗 Activer mon compte via Bitget
                </a>
                <br /> */}
                </p>
                <p className='mt-6 lg:text-lg text-gray-800 dark:text-gray-200'>
                  {/*   💰 Vous devez au moins déposer 100$ sur l'une de ces plateformes. Une fois votre
                compte créé sur Bingx ou Bitget, renseignez dans votre profil l'UID et votre échange
                afin de valider votre compte, en cliquant sur le bouton ci-dessous */}
                  💰 Vous devez au moins déposer 100$ sur BINGX. Une fois votre compte créé,
                  renseignez dans votre profil l'UID afin de valider votre compte, en cliquant sur
                  le bouton ci-dessous
                </p>
                <Link
                  to='/profil'
                  className='mt-5 py-3 px-4 inline-block justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-violet-600 text-white hover:bg-violet-700 disabled:opacity-50 disabled:pointer-events-none'
                >
                  Je complète mon profil
                </Link>
              </div>

              {/* End Title */}
            </div>
            <div className='absolute top-1/2 start-1/2 -z-[1] transform -translate-y-1/2 -translate-x-1/2 w-[340px] h-[340px] border border-dashed border-violet-200 rounded-full dark:border-violet-900/60' />
            <div className='absolute top-1/2 start-1/2 -z-[1] transform -translate-y-1/2 -translate-x-1/2 w-[575px] h-[575px] border border-dashed border-violet-200 rounded-full opacity-80 dark:border-violet-900/60' />
            <div className='absolute top-1/2 start-1/2 -z-[1] transform -translate-y-1/2 -translate-x-1/2 w-[840px] h-[840px] border border-dashed border-violet-200 rounded-full opacity-60 dark:border-violet-900/60' />
            <div className='absolute top-1/2 start-1/2 -z-[1] transform -translate-y-1/2 -translate-x-1/2 w-[1080px] h-[1080px] border border-dashed border-violet-200 rounded-full opacity-40 dark:border-violet-900/60' />
          </div>
          {/* End Hero */}
        </div>
      </DefaultLayout>
    </Fragment>
  );
}
