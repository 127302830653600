import { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { resetAllSlices, RootState, AppDispatch } from '../../store/configureStore';

const NavigationBar = () => {
  const dispatch = useDispatch<AppDispatch>();
  const user = useSelector((state: RootState) => state.auth.user);
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;

  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);

  const toggleMobileNav = () => {
    setIsMobileNavOpen(!isMobileNavOpen);
  };

  const logoutUser = () => {
    dispatch(resetAllSlices());
    navigate('/auth/login');
  };

  const activeRouteClassName =
    'text-xl relative inline-block bg-clip-text bg-gradient-to-tl from-blue-500 to-violet-600 text-transparent';
  const inactiveRouteClassName =
    'text-xl inline-block text-black hover:text-gray-600 dark:text-white dark:hover:text-neutral-300';

  const isActiveRoute = (path: string): boolean => {
    if (path === `${currentPath}${location.hash}`) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <header className='flex flex-wrap md:justify-start md:flex-nowrap z-50 w-full py-7'>
      <nav
        className='relative max-w-7xl w-full flex flex-wrap md:grid md:grid-cols-12 basis-full items-center px-4 md:px-6 md:px-8 mx-auto'
        aria-label='Global'
      >
        <div className='md:col-span-3'>
          {/* Logo */}
          <Link
            className='flex-none rounded-xl text-xl inline-block font-semibold focus:outline-none focus:opacity-80'
            to='/'
            aria-label='Mamos Crypto'
          >
            <div className='flex-none text-3xl font-semibold bg-clip-text bg-gradient-to-tl from-blue-600 to-violet-600 text-transparent'>
              Mamos
            </div>
          </Link>
          {/* End Logo */}
        </div>
        {/* Button Group */}
        <div className='flex items-center gap-x-2 ms-auto py-1 md:ps-6 md:order-3 md:col-span-3'>
          {user ? (
            <button
              type='button'
              onClick={logoutUser}
              className='py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-xl border border-gray-200 text-black hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none dark:border-neutral-700 dark:hover:bg-white/10 dark:text-white dark:hover:text-white'
            >
              Se déconnecter
            </button>
          ) : (
            <button
              type='button'
              onClick={() => navigate('/auth/login')}
              className='py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-xl border border-gray-200 text-black hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none dark:border-neutral-700 dark:hover:bg-white/10 dark:text-white dark:hover:text-white'
            >
              Se connecter
            </button>
          )}

          {user ? (
            <button
              onClick={() => navigate('/profil')}
              type='button'
              className='py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-xl border border-transparent bg-violet-600 text-white hover:bg-violet-500 transition disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-viollet-500'
            >
              Mon profil
            </button>
          ) : (
            <button
              onClick={() => navigate('/auth/register')}
              type='button'
              className='py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-xl border border-transparent bg-violet-600 text-white hover:bg-violet-500 transition disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-viollet-500'
            >
              Inscription
            </button>
          )}

          <div className='md:hidden'>
            <button
              type='button'
              onClick={toggleMobileNav}
              className='hs-collapse-toggle size-[38px] flex justify-center items-center text-sm font-semibold rounded-xl border border-gray-200 text-black hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none dark:text-white dark:border-neutral-700 dark:hover:bg-neutral-700'
              aria-label='Toggle navigation'
            >
              {isMobileNavOpen ? (
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width={24}
                  height={24}
                  viewBox='0 0 24 24'
                  fill='none'
                  stroke='currentColor'
                  strokeWidth={2}
                  strokeLinecap='round'
                  strokeLinejoin='round'
                >
                  <path d='M18 6 6 18' />
                  <path d='m6 6 12 12' />
                </svg>
              ) : (
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width={24}
                  height={24}
                  viewBox='0 0 24 24'
                  fill='none'
                  stroke='currentColor'
                  strokeWidth={2}
                  strokeLinecap='round'
                  strokeLinejoin='round'
                >
                  <line x1={3} x2={21} y1={6} y2={6} />
                  <line x1={3} x2={21} y1={12} y2={12} />
                  <line x1={3} x2={21} y1={18} y2={18} />
                </svg>
              )}
            </button>
          </div>
        </div>
        {/* End Button Group */}
        {/* Collapse */}
        <div
          className={`hs-collapse overflow-hidden transition-all duration-300 basis-full grow md:block md:w-auto md:basis-auto md:order-2 md:col-span-6 ${
            isMobileNavOpen ? 'block' : 'hidden'
          }`}
        >
          <div className='flex flex-col gap-y-4 gap-x-0 mt-5 md:flex-row md:justify-center md:items-center md:gap-y-0 md:gap-x-7 md:mt-0'>
            <div>
              <Link
                className={isActiveRoute('/') ? activeRouteClassName : inactiveRouteClassName}
                to='/'
                aria-current='page'
              >
                Accueil
              </Link>
            </div>
            <div>
              <Link
                className={
                  isActiveRoute('/analyses') ? activeRouteClassName : inactiveRouteClassName
                }
                to='/analyses'
              >
                Analyses
              </Link>
            </div>
            <div>
              <Link
                className={
                  isActiveRoute('/indicateurs') ? activeRouteClassName : inactiveRouteClassName
                }
                to='/indicateurs'
              >
                Indicateurs
              </Link>
            </div>
            {/*    <div>
              <a
                className={
                  isActiveRoute('/#videos') ? activeRouteClassName : inactiveRouteClassName
                }
                href='/#videos'
              >
                Vidéos
              </a>
            </div> */}
            {user && user.roles.includes('ADMIN') && (
              <div>
                <Link
                  to={'/admin/dashboard'}
                  className={
                    isActiveRoute('/admin/dashboard')
                      ? activeRouteClassName
                      : inactiveRouteClassName
                  }
                >
                  Admin
                </Link>
              </div>
            )}
          </div>
        </div>
        {/* End Collapse */}
      </nav>
    </header>
  );
};

export default NavigationBar;
