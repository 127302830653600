import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Indicateur } from '../../types/Indicateur';

import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store/configureStore';
import { getIndicateurs, deleteIndicateur } from '../../store/modules/indicateurs';

import { map, orderBy } from 'lodash';
import { Edit, Eye, PlusCircle, Trash } from 'lucide-react';

import { Button } from '../../components/ui/button';
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from '../../components/ui/card';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '../../components/ui/table';
import { Tabs, TabsContent } from '../../components/ui/tabs';
import AdminLayout from '../../components/layout/AdminLayout';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '../../components/ui/alert-dialog';
import Pagination from '../../components/Pagination';

const IndicateursPageAD = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const indicateurs = useSelector((state: RootState) => state.indicateurs.data);

  const [actualPage, setActualPage] = useState<number>(1);
  const maxPages = useSelector((state: RootState) => state.indicateurs.totalPages);

  const countIndicatuers = useSelector((state: RootState) => state.indicateurs.totalCount);

  const [loadingDelete, setLoadingDelete] = useState<boolean>(false);
  const [indicateurSelected, setIndicateurSelected] = useState<Indicateur | null>(null);

  useEffect(() => {
    dispatch(getIndicateurs(actualPage));
  }, [actualPage]);

  const actionDelete = () => {
    setLoadingDelete(true);

    if (indicateurSelected) {
      return dispatch(deleteIndicateur(indicateurSelected))
        .unwrap()
        .then(() => {
          setLoadingDelete(false);
          setIndicateurSelected(null);
        })
        .catch(() => {
          setLoadingDelete(false);
        });
    }
  };

  return (
    <AdminLayout>
      <AlertDialog>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Supprimer cet indicateur</AlertDialogTitle>
            <AlertDialogDescription>
              Confirmer vous la suppresion de cet indicateur ?
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel
              onClick={() => {
                setIndicateurSelected(null);
              }}
            >
              Annuler
            </AlertDialogCancel>
            <AlertDialogAction disabled={loadingDelete} onClick={() => actionDelete()}>
              Oui
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>

        <div className='flex min-h-screen w-full flex-col'>
          <div className='flex flex-col sm:gap-4 sm:py-4 sm:pl-14'>
            <main className='grid flex-1 items-start gap-4 p-4 sm:px-6 sm:py-0 md:gap-8'>
              <Tabs defaultValue='all'>
                <TabsContent value='all'>
                  <Card>
                    <CardHeader>
                      <CardTitle>Indicateurs</CardTitle>
                    </CardHeader>

                    <CardContent>
                      <Button
                        size='sm'
                        className='h-8 gap-1 mb-4'
                        onClick={() => {
                          setIndicateurSelected(null);
                          navigate('/admin/indicateurs/create');
                        }}
                      >
                        <PlusCircle className='h-3.5 w-3.5' />
                        <span className='sr-only sm:not-sr-only sm:whitespace-nowrap'>
                          Ajouter un indicateur
                        </span>
                      </Button>
                      <Table>
                        <TableHeader>
                          <TableRow>
                            <TableHead className='hidden w-[100px] lg:table-cell'>
                              <span className='sr-only'>Image</span>
                            </TableHead>
                            <TableHead>Nom</TableHead>

                            <TableHead>
                              <span className='sr-only'>Actions</span>
                            </TableHead>
                          </TableRow>
                        </TableHeader>
                        <TableBody>
                          {map(orderBy(indicateurs, ['date'], ['desc']), (i) => (
                            <TableRow key={i.indicateurId}>
                              <TableCell className='font-medium'>{i.name}</TableCell>

                              <TableCell>
                                <div className='hidden items-center gap-3 sm:ml-auto sm:flex'>
                                  <Button
                                    variant='outline'
                                    size='icon'
                                    onClick={() => {
                                      window.open(`/indicateur/${i.indicateurId}`, '_blank');
                                    }}
                                  >
                                    <Eye className='h-4 w-4' />
                                    <span className='sr-only'>Voir</span>
                                  </Button>
                                  <Button
                                    variant='default'
                                    size='icon'
                                    onClick={() => {
                                      navigate(`/admin/indicateurs/edit/${i.indicateurId}`);
                                    }}
                                  >
                                    <Edit className='h-4 w-4' />
                                    <span className='sr-only'>Modifier</span>
                                  </Button>
                                  <AlertDialogTrigger asChild>
                                    <Button
                                      variant='destructive'
                                      size='icon'
                                      onClick={() => {
                                        setIndicateurSelected(i);
                                      }}
                                    >
                                      <Trash className='h-4 w-4' />
                                      <span className='sr-only'>Supprimer</span>
                                    </Button>
                                  </AlertDialogTrigger>
                                </div>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </CardContent>
                    <Pagination
                      pageSelected={actualPage}
                      pageMax={maxPages}
                      onChangePage={(p) => setActualPage(p)}
                    />
                    <CardFooter>
                      <div className='text-xs text-muted-foreground'>
                        <strong>{countIndicatuers || 0}</strong> indicateurs
                      </div>
                    </CardFooter>
                  </Card>
                </TabsContent>
              </Tabs>
            </main>
          </div>
        </div>
      </AlertDialog>
    </AdminLayout>
  );
};
export default IndicateursPageAD;
