import axios from "axios";

import { normalize, Schema } from "normalizr";

import FormData from "form-data";
//import { map, forEach } from 'lodash';

import storage from "redux-persist/lib/storage";
import API_URL from "../constants/config";

const api = axios.create({
  baseURL: `${API_URL}`,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

// Function to set the Bearer authentication token in the Axios instance
export const setBearerToken = (token: string | null) => {
  if (token) {
    api.defaults.headers["Authorization"] = `Bearer ${token}`;
  } else {
    delete api.defaults.headers["Authorization"];
  }
};

// Function to make API calls using the custom Axios instance
export const callApi = async (
  url: string,
  method: string,
  schema?: Schema | null,
  data?: object | FormData,
  isUpload?: boolean
) => {
  //let formData = new FormData();

  let persistStorage;
  persistStorage = await storage.getItem("persist:jarvis");

  if (process.env.NODE_ENV === "development") {
    console.info("[CALL API] URL: ", `${API_URL}${url}`);
  }
  if (isUpload) {
    api.defaults.headers["Content-Type"] = "multipart/form-data";
    api.defaults.headers["Accept"] = "application/json";
  } else {
    api.defaults.headers["Content-Type"] = "application/json";
  }

  if (persistStorage !== null) {
    const store = JSON.parse(persistStorage);
    const storeAuth = JSON.parse(store.auth);

    if (!api.defaults.headers["Authorization"]) {
      // Set the token again in case of token loss
      if (storeAuth.token) {
        //console.info("JWT TOKEN: Bearer ", storeAuth.token);
        setBearerToken(storeAuth.token);
      }
      if (storeAuth.id && !storeAuth.token) {
        //console.error("No JWT TOKEN in the request");
      }
    }
  }

  return api
    .request({
      url,
      method,
      data,
    })
    .then((response) => {
      if (schema) {
        const normalizedData = normalize(response.data, [schema]);
        return normalizedData;
      } else {
        return response.data;
      }
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.data.message) {
          throw String(error.response.data.message);
        }
      } else if (error.request) {
        throw String("No response received from the server");
      }
    });
};
