import { Link } from 'react-router-dom';

const Hero = () => {
  return (
    <div>
      <div>
        <div className='max-w-[85rem] mx-auto px-4 sm:px-6 lg:px-8 py-24 space-y-8'>
          <div className='max-w-3xl text-center mx-auto'>
            <h1 className='block font-bold text-gray-800 text-4xl md:text-5xl lg:text-6xl dark:text-gray-200'>
              🚀 Révolutionnez votre trading avec
              <span className='bg-clip-text bg-gradient-to-tl from-blue-600 to-violet-600 text-transparent'>
                {' '}
                Mamos Crypto
              </span>
            </h1>
          </div>
          {/* End Title */}
          <div className='max-w-3xl text-center mx-auto'>
            <p className='text-lg text-gray-400'>
              L'Intelligence artificielle au service de votre portefeuille
            </p>
          </div>
          {/* Buttons */}
          <div className='text-center'>
            <Link
              to='auth/register'
              className='inline-flex justify-center items-center gap-x-3 text-center bg-gradient-to-tl from-blue-600 to-violet-600 shadow-lg shadow-transparent hover:shadow-blue-700/50 border border-transparent text-white text-sm font-medium rounded-full focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 focus:ring-offset-white py-3 px-6 dark:focus:ring-offset-gray-800'
            >
              Commencer maintenant
              <svg
                className='flex-shrink-0 size-4'
                xmlns='http://www.w3.org/2000/svg'
                width={24}
                height={24}
                viewBox='0 0 24 24'
                fill='none'
                stroke='currentColor'
                strokeWidth={2}
                strokeLinecap='round'
                strokeLinejoin='round'
              >
                <path d='m9 18 6-6-6-6' />
              </svg>
            </Link>
          </div>
          {/* End Buttons */}
        </div>
      </div>
    </div>
  );
};

export default Hero;
