import { Fragment, useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import { isAdmin } from '../../lib/utils';

import { Comment } from '../../types/Comment';
import { ApiError } from '../../types/Api';
import API_URL from '../../constants/config';

import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store/configureStore';
import {
  commentAnalyse,
  toogleLikeAnalyse,
  toogleLikeComment,
  deleteComment,
  getAnalyse,
  updateComment,
} from '../../store/modules/analyses';

import { Edit, Trash, Save, CircleX } from 'lucide-react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

import { includes, map, orderBy } from 'lodash';

import moment from 'moment';
import 'moment/locale/fr';

import DefaultLayout from '../../components/layout/DefaultLayout';

const AnalysePage = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { analyseId } = useParams();
  const [editMode, setEditMode] = useState<boolean>(false);
  const [displayAddComment, setDisplayAddComment] = useState<boolean>(false);
  const [loadingSubmitComment, setLoadingSubmitComment] = useState<boolean>(false);
  const [comment, setComment] = useState<string>('');
  const [commentSelected, setCommentSelected] = useState<Comment | null>(null);

  const navigation = useNavigate();

  const analyse = useSelector((state: RootState) => state.analyses.data[Number(analyseId)]);

  const user = useSelector((state: RootState) => state.auth.user);

  useEffect(() => {
    if (user && !isAdmin(user) && !user.isAffiliated) {
      navigation('/affiliation');
    }

    if (analyseId) {
      dispatch(getAnalyse(Number(analyseId)))
        .unwrap()
        .catch((e: ApiError) => {
          if (e.message && e.message === 'Cette analyse existe pas') {
            navigation('/404');
          }
        });
    }
  }, []);

  const submitComment = () => {
    setLoadingSubmitComment(true);
    return dispatch(commentAnalyse({ analyseId: Number(analyseId), text: comment }))
      .unwrap()
      .then(() => {
        setDisplayAddComment(false);
        setComment('');
        setLoadingSubmitComment(false);
      })
      .catch(() => setLoadingSubmitComment(false));
  };

  const submitUpdateComment = () => {
    setLoadingSubmitComment(true);
    if (commentSelected) {
      return dispatch(updateComment({ comment: commentSelected, text: comment }))
        .unwrap()
        .then(() => {
          setCommentSelected(null);
          setEditMode(false);
          setComment('');
          setLoadingSubmitComment(false);
        })
        .catch(() => setLoadingSubmitComment(false));
    }
  };

  const isAuthorizeDeleteEdit = (comment: Comment): boolean => {
    if ((user && user.userId === comment.userId) || (user && user.roles.includes('ADMIN'))) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <Fragment>
      <Helmet>
        <title>
          Mamos Crypto [
          {`Analyse ${(analyse && analyse.crypto && analyse.crypto.name) || ''} du ${moment(
            analyse && analyse.date
          ).format('DD/MM/YYYY')}`}{' '}
          ]
        </title>
      </Helmet>
      <DefaultLayout>
        {/* Blog Article */}
        <div className='max-w-3xl px-4 pt-6 lg:pt-10 pb-12 sm:px-6 lg:px-8 mx-auto'>
          <div className='max-w-2xl'>
            <div
              onClick={() => navigation('/analyses')}
              className='mb-7 text-center inline-flex items-center align-middle text-lg text-gray-600 decoration-2 hover:underline dark:text-blue-500'
            >
              <svg
                className='flex-shrink-0 size-4'
                xmlns='http://www.w3.org/2000/svg'
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
                stroke='currentColor'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
              >
                <path d='m15 18-6-6 6-6'></path>
              </svg>
              Retour aux analyses
            </div>
            {/* Avatar Media */}
            <div className='flex justify-between items-center mb-6'>
              <div className='flex w-full sm:items-center gap-x-5 sm:gap-x-3'>
                <div className='flex-shrink-0'>
                  <img
                    className='size-12 rounded-full'
                    src={`/icons-cryptos/${(analyse && analyse.crypto && analyse.crypto.symbol) || ''
                      }.png`}
                    alt={`Analyse ${(analyse && analyse.crypto && analyse.crypto.name) || ''}`}
                  />
                </div>
                <div className='grow'>
                  <div className='flex justify-between items-center gap-x-2'>
                    <div>
                      {/* Tooltip */}
                      <div className='hs-tooltip inline-block [--trigger:hover] [--placement:bottom]'>
                        <div className='hs-tooltip-toggle sm:mb-1 block text-start cursor-pointer'>
                          <span className='font-semibold text-gray-800 dark:text-gray-200'>
                            Analyse {(analyse && analyse.crypto && analyse.crypto.name) || ''}
                          </span>
                          {/* Dropdown Card */}
                          <div
                            className='hs-tooltip-content hs-tooltip-shown:opacity-100 hs-tooltip-shown:visible opacity-0 transition-opacity inline-block absolute invisible z-10 max-w-xs cursor-default bg-gray-900 divide-y divide-gray-700 shadow-lg rounded-xl dark:bg-black'
                            role='tooltip'
                          >
                            {/* Body */}
                            <div className='p-4 sm:p-5'>
                              <div className='mb-2 flex w-full sm:items-center gap-x-5 sm:gap-x-3'>
                                <div className='flex-shrink-0'>
                                  <img
                                    className='size-8 rounded-full'
                                    src={`/icons-cryptos/${(analyse && analyse.crypto && analyse.crypto.symbol) || ''
                                      }.png`}
                                    alt={`Analyse ${(analyse && analyse.crypto && analyse.crypto.name) || ''
                                      }`}
                                  />
                                </div>
                                <div className='grow'>
                                  <p className='text-lg font-semibold text-gray-200'>
                                    Analyse{' '}
                                    {(analyse && analyse.crypto && analyse.crypto.name) || ''}
                                  </p>
                                </div>
                              </div>
                            </div>
                            {/* End Body */}
                            {/* Footer */}
                            <div className='flex justify-between items-center px-4 py-3 sm:px-5'>
                              <ul className='text-xs space-x-3'>
                                <li className='inline-block'>
                                  <span className='font-semibold text-gray-200'>56</span>
                                  <span className='text-gray-400'>articles</span>
                                </li>
                                <li className='inline-block'>
                                  <span className='font-semibold text-gray-200'>1k+</span>
                                  <span className='text-gray-400'>followers</span>
                                </li>
                              </ul>
                              <div>
                                <button
                                  type='button'
                                  className='py-1.5 px-2.5 inline-flex items-center gap-x-2 text-xs font-semibold rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none'
                                >
                                  <svg
                                    className='flex-shrink-0 size-3.5'
                                    xmlns='http://www.w3.org/2000/svg'
                                    width={16}
                                    height={16}
                                    fill='currentColor'
                                    viewBox='0 0 16 16'
                                  >
                                    <path d='M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z' />
                                    <path
                                      fillRule='evenodd'
                                      d='M13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5z'
                                    />
                                  </svg>
                                  Follow
                                </button>
                              </div>
                            </div>
                            {/* End Footer */}
                          </div>
                          {/* End Dropdown Card */}
                        </div>
                      </div>
                      {/* End Tooltip */}
                      <ul className='text-xs text-gray-500'>
                        <li className='inline-block relative pe-6 last:pe-0 last-of-type:before:hidden before:absolute before:top-1/2 before:end-2 before:-translate-y-1/2 before:size-1 before:bg-gray-300 before:rounded-full dark:text-gray-400 dark:before:bg-gray-600'>
                          {moment(analyse && analyse.date).format('DD/MM/YYYY')}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* End Avatar Media */}
            {/* Content */}
            <div className='space-y-5 md:space-y-8'>
              <div className='space-y-3'>
                <h2 className='text-2xl font-bold md:text-3xl dark:text-white'>
                  Analyse {(analyse && analyse.crypto && analyse.crypto.name) || ''} du{' '}
                  {moment(analyse && analyse.date).format('DD/MM/YYYY')}
                </h2>
              </div>
              {analyse && analyse.image && (
                <figure>
                  <img
                    className='w-full object-cover rounded-xl'
                    src={`${API_URL}/uploads/${analyse.image}`}
                    alt='Image Description'
                  />
                  {/*  <figcaption className='mt-3 text-sm text-center text-gray-500 dark:text-neutral-500'>
                  A woman sitting at a table.
                </figcaption> */}
                </figure>
              )}

              {analyse && (
                <div className='space-y-3'>
                  <p className='text-lg text-gray-800 dark:text-gray-200'></p>{' '}
                  {analyse &&
                    analyse.texte &&
                    (<ReactMarkdown children={analyse.texte} remarkPlugins={[remarkGfm]} /> || '')}
                </div>
              )}

              {analyse && (
                <div className='space-y-3'>
                  <h3 className='text-2xl font-semibold dark:text-white'>📊 Note de confiance</h3>
                  <p className='text-lg text-gray-800 dark:text-gray-200'>
                    {`${analyse.trustNote || 0}/10`}
                  </p>
                </div>
              )}

              <div>
                <a
                  className='m-1 inline-flex items-center gap-1.5 py-2 px-3 rounded-full text-sm bg-gray-100 text-gray-800 hover:bg-gray-200 dark:bg-gray-800 dark:hover:bg-gray-700 dark:text-gray-200'
                  href='#'
                >
                  {(analyse && analyse.crypto.name) || ''}
                </a>
              </div>
            </div>
            {/* End Content */}
          </div>
        </div>
        {/* End Blog Article */}
        {/* Sticky Share Group */}
        <div className='sticky bottom-6 inset-x-0 text-center'>
          <div className='inline-block bg-white shadow-md rounded-full py-3 px-4 dark:bg-gray-800'>
            <div className='flex items-center gap-x-1.5'>
              {/* Button */}
              <div className='hs-tooltip inline-block'>
                <button
                  type='button'
                  disabled={!user}
                  onClick={() => dispatch(toogleLikeAnalyse(analyse.analyseId))}
                  className='hs-tooltip-toggle flex items-center gap-x-2 text-sm text-gray-500 hover:text-gray-800 dark:text-gray-400 dark:hover:text-gray-200'
                >
                  <svg
                    className='flex-shrink-0 size-4'
                    xmlns='http://www.w3.org/2000/svg'
                    width={24}
                    height={24}
                    viewBox='0 0 24 24'
                    fill={analyse && user && analyse.likes && includes(analyse.likes, user?.userId) ? "red" : 'none'}
                    stroke={analyse && user && analyse.likes && includes(analyse.likes, user?.userId) ? "red" : 'currentColor'}
                    strokeWidth={2}
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  >
                    <path d='M19 14c1.49-1.46 3-3.21 3-5.5A5.5 5.5 0 0 0 16.5 3c-1.76 0-3 .5-4.5 2-1.5-1.5-2.74-2-4.5-2A5.5 5.5 0 0 0 2 8.5c0 2.3 1.5 4.05 3 5.5l7 7Z' />
                  </svg>
                  {(analyse && analyse.likes && analyse.likes.length) || 0}
                  <span
                    className='hs-tooltip-content hs-tooltip-shown:opacity-100 hs-tooltip-shown:visible opacity-0 transition-opacity inline-block absolute invisible z-10 py-1 px-2 bg-gray-900 text-xs font-medium text-white rounded shadow-sm dark:bg-black'
                    role='tooltip'
                  >
                    Likes
                  </span>
                </button>
              </div>
              {/* Button */}
              <div className='block h-3 border-e border-gray-300 mx-3 dark:border-gray-600' />
              {/* Button */}
              <div className='hs-tooltip inline-block'>
                <button
                  type='button'
                  disabled={!user}
                  onClick={() => {
                    setComment('');
                    setCommentSelected(null);
                    setEditMode(false);
                    setDisplayAddComment(!displayAddComment);
                  }}
                  className='hs-tooltip-toggle flex items-center gap-x-2 text-sm text-gray-500 hover:text-gray-800 dark:text-gray-400 dark:hover:text-gray-200'
                >
                  <svg
                    className='flex-shrink-0 size-4'
                    xmlns='http://www.w3.org/2000/svg'
                    width={24}
                    height={24}
                    viewBox='0 0 24 24'
                    fill='none'
                    stroke='currentColor'
                    strokeWidth={2}
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  >
                    <path d='m3 21 1.9-5.7a8.5 8.5 0 1 1 3.8 3.8z' />
                  </svg>
                  {(analyse && analyse.comments && analyse.comments.length) || '0'}
                  <span
                    className='hs-tooltip-content hs-tooltip-shown:opacity-100 hs-tooltip-shown:visible opacity-0 transition-opacity inline-block absolute invisible z-10 py-1 px-2 bg-gray-900 text-xs font-medium text-white rounded shadow-sm dark:bg-black'
                    role='tooltip'
                  >
                    Commenter
                  </span>
                </button>
              </div>
              {/* Button */}
            </div>
          </div>
        </div>
        {/* End Sticky Share Group */}

        <div className='max-w-3xl px-4 py-8 mx-auto'>
          <h2 className='text-2xl font-bold mb-4'>Commentaires</h2>
          {displayAddComment && (
            <div className='max-w-3xl px-4 py-8 mx-auto'>
              {/* Formulaire de commentaire */}
              <div className='flex items-center space-x-4'>
                <textarea
                  className='w-full p-3 border rounded-lg focus:outline-none focus:ring focus:border-blue-300'
                  ////rows="3"
                  placeholder='Ajouter un commentaire...'
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                />
                <button
                  type='button'
                  disabled={loadingSubmitComment || comment.length === 0}
                  className='px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600'
                  onClick={() => submitComment()}
                >
                  Ajouter
                </button>
              </div>
            </div>
          )}

          {analyse &&
            analyse.comments &&
            analyse.comments.length > 0 &&
            map(orderBy(analyse.comments, ['createdAt'], ['desc']), (c: Comment) => (
              <div
                key={c.commentId}
                className='dark:bg-gray-800 rounded-lg p-4 mb-4'
                id={`commentaire_${c.commentId}`}
              >
                <div className='flex items-center justify-between mb-1'>
                  <div className='flex items-center'>
                    <img
                      src={
                        c.user && c.user.avatar
                          ? `${API_URL}/uploads/${c.user.avatar}`
                          : '/avatar.webp'
                      }
                      alt='Avatar'
                      className='h-8 w-8 rounded-full mr-2'
                    />
                    <span className='font-bold text-white'>{c.user && c.user.pseudo}</span>
                  </div>
                  <div className='flex items-center'>
                    <button
                      type='button'
                      onClick={() => dispatch(toogleLikeComment(c))}
                      className='mr-4 hs-tooltip-toggle flex items-center gap-x-2 text-sm text-gray-500 hover:text-gray-800 dark:text-gray-400 dark:hover:text-gray-200'
                    >
                      <svg
                        className='flex-shrink-0 size-4'
                        xmlns='http://www.w3.org/2000/svg'
                        width={24}
                        height={24}
                        viewBox='0 0 24 24'
                        fill='none'
                        stroke='currentColor'
                        strokeWidth={2}
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      >
                        <path d='M19 14c1.49-1.46 3-3.21 3-5.5A5.5 5.5 0 0 0 16.5 3c-1.76 0-3 .5-4.5 2-1.5-1.5-2.74-2-4.5-2A5.5 5.5 0 0 0 2 8.5c0 2.3 1.5 4.05 3 5.5l7 7Z' />
                      </svg>
                      {(c && c.likes && c.likes.length) || '0'}
                      <span
                        className='hs-tooltip-content hs-tooltip-shown:opacity-100 hs-tooltip-shown:visible opacity-0 transition-opacity inline-block absolute invisible z-10 py-1 px-2 bg-gray-900 text-xs font-medium text-white rounded shadow-sm dark:bg-black'
                        role='tooltip'
                      >
                        Likes
                      </span>
                    </button>
                    {isAuthorizeDeleteEdit(c) && (
                      <Fragment>
                        {editMode &&
                          commentSelected &&
                          commentSelected.commentId === c.commentId ? (
                          <button
                            onClick={() => {
                              setEditMode(false);
                              setCommentSelected(null);
                              setComment('');
                            }}
                            className='flex items-center px-3 py-1 bg-red-500 text-white rounded-md hover:bg-red-600 focus:outline-none mr-2'
                          >
                            <CircleX />
                          </button>
                        ) : (
                          <button
                            onClick={() => {
                              setEditMode(!editMode);
                              setDisplayAddComment(false);

                              if (!editMode) {
                                setCommentSelected(c);
                                setComment(c.content);
                              } else {
                                setCommentSelected(null);
                                setComment('');
                              }
                            }}
                            className='flex items-center px-3 py-1 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none mr-2'
                          >
                            <Edit />
                          </button>
                        )}
                        {editMode &&
                          commentSelected &&
                          commentSelected.commentId === c.commentId ? (
                          <button
                            onClick={() => submitUpdateComment()}
                            className='flex items-center px-3 py-1 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none'
                          >
                            <Save />
                          </button>
                        ) : (
                          <button
                            onClick={() => dispatch(deleteComment(c))}
                            className='flex items-center px-3 py-1 bg-red-500 text-white rounded-md hover:bg-red-600 focus:outline-none'
                          >
                            <Trash />
                          </button>
                        )}
                      </Fragment>
                    )}
                  </div>
                </div>
                <p className='text-gray-500 mb-2'>
                  {moment(c.createdAt).format('DD-MM-YYYY HH:mm')}
                </p>
                {editMode &&
                  commentSelected &&
                  commentSelected.commentId === c.commentId &&
                  comment ? (
                  <textarea
                    className='w-full p-3 border rounded-lg focus:outline-none focus:ring focus:border-blue-300'
                    placeholder='Ajouter un commentaire...'
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                  />
                ) : (
                  <p className='text-white'>{c.content}</p>
                )}
              </div>
            ))}
        </div>
      </DefaultLayout>
    </Fragment>
  );
};
export default AnalysePage;
