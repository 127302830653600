import { useEffect, useState, Fragment } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';

import { isAdmin } from '../../lib/utils';

import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store/configureStore';
import { getCryptos } from '../../store/modules/cryptos';
import { getAnalyses } from '../../store/modules/analyses';

import { isEmpty, map, orderBy } from 'lodash';

import DefaultLayout from '../../components/layout/DefaultLayout';
import CardAnalyse from '../../components/analyses/CardAnalyse';
import Pagination from '../../components/Pagination';

const AnalysesPage = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const [actualPage, setActualPage] = useState<number>(1);
  const user = useSelector((state: RootState) => state.auth.user);

  const analyses = useSelector((state: RootState) => state.analyses.data);

  const maxPages = useSelector((state: RootState) => state.analyses.totalPages);

  useEffect(() => {
    if (user && !isAdmin(user) && !user.isAffiliated) {
      navigate("/affiliation");
    }
    dispatch(getCryptos());
  }, []);

  useEffect(() => {
    dispatch(getAnalyses(actualPage));
  }, [actualPage]);

  return (
    <Fragment>
      <Helmet>
        <title>Mamos Crypto [ Analyses ]</title>
      </Helmet>
      <DefaultLayout>
        <>
          {/* Card Blog */}
          <div className='max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto'>
            {/* Title */}
            <div className='max-w-2xl text-center mx-auto mb-10 lg:mb-14'>
              <h2 className='text-2xl font-bold md:text-4xl md:leading-tight dark:text-white'>
                Analyses
              </h2>
              <p className='mt-1 text-gray-600 dark:text-gray-400'>
                Découvrez nos dernières analyses
              </p>
            </div>
            {/* End Title */}
            {/* Grid */}
            <div className='grid sm:grid-cols-2 lg:grid-cols-4 gap-6 mb-10 lg:mb-14'>
              {map(orderBy(analyses, ['date'], ['desc']), (a) => (
                <CardAnalyse analyse={a} key={a.analyseId} />
              ))}
            </div>
            {/* End Grid */}
            {analyses && !isEmpty(analyses) && (
              <Pagination
                pageSelected={actualPage}
                pageMax={maxPages}
                onChangePage={(p) => setActualPage(p)}
              />
            )}
          </div>
          {/* End Card Blog */}
        </>
      </DefaultLayout>
    </Fragment>
  );
};
export default AnalysesPage;
