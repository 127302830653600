import React from 'react';
import { Editor } from '@tinymce/tinymce-react';
import API_URL from '../constants/config';

interface MyEditorProps {
  value: string;
  onEditorChange?: (content: string) => void;
}

const EditorTinymce: React.FC<MyEditorProps> = ({ value, onEditorChange }) => {
  return (
    <Editor
      apiKey='vls3dbniui1nzvq3fxsub3gntvc9u94gfoqsv8e13tqjsdfy'
      value={value}
      init={{
        language: 'fr_FR',
        height: 800,
        menubar: 'edit view insert format tools',
        menu: {
          edit: {
            title: 'Edit',
            items: 'undo redo | cut copy paste pastetext | selectall | searchreplace',
          },
          view: {
            title: 'View',
            items: 'code | visualaid visualchars visualblocks | spellchecker | fullscreen',
          },
          insert: {
            title: 'Insert',
            items:
              'image link media template codesample inserttable | charmap emoticons hr | pagebreak nonbreaking anchor toc | insertdatetime',
          },
          format: {
            title: 'Format',
            items:
              'bold italic underline strikethrough superscript subscript codeformat | formats blockformats fontformats fontsizes align lineheight | forecolor backcolor | removeformat',
          },
          tools: {
            title: 'Tools',
            items: 'spellchecker spellcheckerlanguage | code wordcount',
          },
          table: {
            title: 'Table',
            items: 'inserttable | cell row column | tableprops deletetable',
          },
        },
        plugins: [
          'advlist',
          'autolink',
          'lists',
          'link',
          'image',
          'charmap',
          'anchor',
          'searchreplace',
          'visualblocks',
          'code',
          'fullscreen',
          'insertdatetime',
          'media',
          'table',
          'code',
          'help',
          'wordcount',
        ],
        toolbar:
          'undo redo | blocks | ' +
          'bold italic forecolor | fontsize | alignleft aligncenter ' +
          'alignright alignjustify | bullist numlist outdent indent | ' +
          'removeformat | image link | help | myCustomButton', // Ajout du bouton personnalisé ici
        skin: 'oxide-dark', // Activer le thème sombre
        content_css: 'dark', // Appliquer les styles sombres au contenu
        content_style: `
        body { font-family: Helvetica, Arial, sans-serif; font-size: 14px; color: #FFFFFF; }
        h1 { font-size: 2em; }
        h2 { font-size: 1.8em; }
        h3 { font-size: 1.5em; }
      `,
        body_class: 'custom-editor-body',
        image_advtab: true,
        image_uploadtab: true,
        images_upload_url: `${API_URL}/uploads`,

        images_upload_handler: (blobInfo, progress) =>
          new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open('POST', 'http://localhost:3000/uploads');

            xhr.upload.onprogress = (e) => {
              progress((e.loaded / e.total) * 100);
            };

            xhr.onload = () => {
              if (xhr.status === 201) {
                const imageName = xhr.response;
                resolve(`${API_URL}/uploads/${imageName}`);
              } else {
                reject(`HTTP Error: ${xhr.status}`);
              }
            };

            xhr.onerror = () => reject('Image upload failed');

            const formData = new FormData();
            formData.append('file', blobInfo.blob(), blobInfo.filename());
            xhr.send(formData);
          }),
        setup: (editor) => {
          // Ajout du bouton personnalisé
          editor.ui.registry.addButton('myCustomButton', {
            text: 'Insérer un bouton',
            onAction: () => {
              const linkText = 'Cliquez ici';
              const linkUrl = 'https://www.example.com';
              const linkHtml = `<a type="button" href="${linkUrl}" class="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-xl border border-transparent bg-violet-600 text-white hover:bg-violet-500 transition disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-viollet-500">${linkText}</a>`;

              editor.insertContent(linkHtml);
            },
          });
        },
      }}
      onEditorChange={onEditorChange}
    />
  );
};

export default EditorTinymce;
