import { useEffect, useState } from 'react';

import { User } from '../../types/User';

import API_URL from '../../constants/config';

import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store/configureStore';
import { deleteUser, getUsers, toogleActiveisAffiliated } from '../../store/modules/users';

import { map, orderBy, values } from 'lodash';
import moment from 'moment';

import { Trash } from 'lucide-react';

import { Button } from '../../components/ui/button';
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from '../../components/ui/card';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '../../components/ui/table';
import { Tabs, TabsContent } from '../../components/ui/tabs';
import AdminLayout from '../../components/layout/AdminLayout';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '../../components/ui/alert-dialog';
import { Avatar, AvatarFallback, AvatarImage } from '../../components/ui/avatar';
import { Switch } from '../../components/ui/switch';
import Pagination from '../../components/Pagination';

const UtilisateursPageAD = () => {
  const dispatch = useDispatch<AppDispatch>();

  const users = useSelector((state: RootState) => state.users.data);

  const [actualPage, setActualPage] = useState<number>(1);
  const maxPages = useSelector((state: RootState) => state.users.totalPages);

  const [loadingDelete, setLoadingDelete] = useState<boolean>(false);
  const [userSelected, setUserSelected] = useState<User | null>(null);

  useEffect(() => {
    dispatch(getUsers(actualPage));
  }, [actualPage]);

  const actionDelete = () => {
    setLoadingDelete(true);

    if (userSelected) {
      return dispatch(deleteUser(userSelected))
        .unwrap()
        .then(() => {
          setLoadingDelete(false);
          setUserSelected(null);
        })
        .catch(() => {
          setLoadingDelete(false);
        });
    }
  };

  return (
    <AdminLayout>
      <AlertDialog>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Supprimer cet utilisateur</AlertDialogTitle>
            <AlertDialogDescription>
              Confirmer vous la suppresion de cet utilisateur ?
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel
              onClick={() => {
                setUserSelected(null);
              }}
            >
              Annuler
            </AlertDialogCancel>
            <AlertDialogAction disabled={loadingDelete} onClick={() => actionDelete()}>
              Oui
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>

        <div className='flex min-h-screen w-full flex-col'>
          <div className='flex flex-col sm:gap-4 sm:py-4 sm:pl-14'>
            <main className='grid flex-1 items-start gap-4 p-4 sm:px-6 sm:py-0 md:gap-8'>
              <Tabs defaultValue='all'>
                <TabsContent value='all'>
                  <Card>
                    <CardHeader>
                      <CardTitle>Utilisateurs</CardTitle>
                    </CardHeader>

                    <CardContent>
                      <Table>
                        <TableHeader>
                          <TableRow>
                            <TableHead>Avatar</TableHead>
                            <TableHead>Pseudo</TableHead>
                            <TableHead>Email</TableHead>
                            <TableHead>Rôles</TableHead>
                            <TableHead>Level</TableHead>
                            <TableHead>Exchange</TableHead>
                            <TableHead>UID</TableHead>
                            <TableHead>Activé</TableHead>
                            <TableHead className='hidden md:table-cell'>Création</TableHead>
                            <TableHead className='hidden md:table-cell'>
                              Dernière connexion
                            </TableHead>
                            <TableHead>
                              <span className='sr-only'>Actions</span>
                            </TableHead>
                          </TableRow>
                        </TableHeader>
                        <TableBody>
                          {map(orderBy(users, ['createdAt'], ['desc']), (u) => (
                            <TableRow key={u.userId}>
                              <TableCell className='font-medium'>
                                <Avatar className='hidden h-9 w-9 sm:flex'>
                                  <AvatarImage
                                    src={
                                      u && u.avatar
                                        ? `${API_URL}/uploads/${u.avatar}`
                                        : '/avatar.webp'
                                    }
                                    alt='Avatar'
                                  />
                                  <AvatarFallback>{u.pseudo}</AvatarFallback>
                                </Avatar>
                              </TableCell>

                              <TableCell className='font-medium'>{u.pseudo}</TableCell>
                              <TableCell className='font-medium'>{u.email}</TableCell>
                              <TableCell className='font-medium'>
                                {map(u.roles, (role) => `${role} `)}
                              </TableCell>
                              <TableCell className='font-medium'>{u.level}</TableCell>
                              <TableCell className='font-medium'>{u.exchange || ''}</TableCell>
                              <TableCell className='font-medium'>{u.uid || ''}</TableCell>
                              <TableCell className='font-medium'>
                                <Switch
                                  checked={u.isAffiliated}
                                  onCheckedChange={() =>
                                    dispatch(toogleActiveisAffiliated(u.userId))
                                  }
                                />
                              </TableCell>

                              <TableCell className='hidden md:table-cell'>
                                {moment(u.createdAt).format('DD/MM/YYYY HH:mm')}
                              </TableCell>

                              <TableCell className='hidden md:table-cell'>
                                {moment(u.last_connexion).format('DD/MM/YYYY HH:mm')}
                              </TableCell>
                              <TableCell>
                                <div className='hidden items-center gap-3 sm:ml-auto sm:flex'>
                                  <AlertDialogTrigger asChild>
                                    <Button
                                      variant='destructive'
                                      size='icon'
                                      onClick={() => {
                                        setUserSelected(u);
                                      }}
                                    >
                                      <Trash className='h-4 w-4' />
                                      <span className='sr-only'>Supprimer</span>
                                    </Button>
                                  </AlertDialogTrigger>
                                </div>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </CardContent>
                    <Pagination
                      pageSelected={actualPage}
                      pageMax={maxPages}
                      onChangePage={(p) => setActualPage(p)}
                    />
                    <CardFooter>
                      <div className='text-xs text-muted-foreground'>
                        <strong>{(users && values(users).length) || 0}</strong> utilisateurs
                      </div>
                    </CardFooter>
                  </Card>
                </TabsContent>
              </Tabs>
            </main>
          </div>
        </div>
      </AlertDialog>
    </AdminLayout>
  );
};
export default UtilisateursPageAD;
