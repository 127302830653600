import { Fragment, ReactNode } from "react";

import Footer from "./Footer";
import NavigationBar from "./NavigationBar";

interface DefaultLayoutProps {
  children: ReactNode;
}

const DefaultLayout = ({ children }: DefaultLayoutProps) => {
  return (
    <Fragment>
      <NavigationBar />
      {children}
      <Footer />
    </Fragment>
  );
};

export default DefaultLayout;
