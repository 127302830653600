import { useState, useRef, Fragment } from 'react';

import API_URL from '../../constants/config';

import { deleteFile, uploadFile } from '../../lib/utils';

import { AppDispatch, RootState } from '../../store/configureStore';
import { useDispatch, useSelector } from 'react-redux';
import { updateAvatar } from '../../store/modules/auth';

import { ClipLoader } from 'react-spinners';
import { Trash2 } from 'lucide-react';

const UpdateAvatarForm = () => {
  const [loadingDeleteAvatar, setLoadingDeleteAvatar] = useState<boolean>(false);

  const [loadingUploadAvatar, setLoadingUploadAvatar] = useState<boolean>(false);

  const fileInputRef = useRef(null);
  const user = useSelector((state: RootState) => state.auth.user);

  const dispatch = useDispatch<AppDispatch>();

  const handleAvatarInputChange = (e: any) => {
    if (user) {
      const file = e.target.files[0];

      const formData = new FormData();
      formData.append('file', file);

      if (formData) {
        setLoadingUploadAvatar(true);

        return uploadFile(formData)
          .then((fileName) => {
            dispatch(updateAvatar(fileName)).then(() => {
              setLoadingUploadAvatar(false);
            });
          })
          .catch(() => {
            setLoadingUploadAvatar(false);
          });
      }
    }
  };

  const deleteAvatar = (filename: string) => {
    setLoadingDeleteAvatar(true);
    if (user) {
      return deleteFile(filename).then(() => {
        dispatch(updateAvatar(null))
          .then(() => {
            setLoadingDeleteAvatar(false);
          })
          .catch(() => {
            setLoadingDeleteAvatar(false);
          });
      });
    }
  };

  return (
    <Fragment>
      <div className='sm:col-span-3'>
        <label className='inline-block text-sm text-gray-800 mt-2.5 dark:text-neutral-200'>
          Avatar
        </label>
      </div>
      {/* End Col */}
      <div className='sm:col-span-9'>
        <div className='flex items-center gap-5'>
          <img
            className='inline-block size-16 rounded-full ring-2 ring-white dark:ring-neutral-900'
            src={user && user.avatar ? `${API_URL}/uploads/${user.avatar}` : '/avatar.webp'}
            alt='Image Description'
          />
          <div className='flex gap-x-2'>
            <div>
              <input
                ref={fileInputRef}
                type='file'
                accept='image/*'
                onChange={handleAvatarInputChange}
                style={{ display: 'none' }}
              />

              {user && user.avatar ? (
                <button
                  disabled={loadingDeleteAvatar}
                  onClick={() => {
                    if (user.avatar) {
                      deleteAvatar(user.avatar);
                    }
                  }}
                  type='button'
                  className='py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:text-white dark:hover:bg-neutral-800'
                >
                  <Trash2 size={24} />
                  Supprimer mon avatar
                </button>
              ) : (
                <button
                  disabled={loadingUploadAvatar}
                  onClick={() => {
                    if (fileInputRef.current) {
                      (fileInputRef.current as HTMLInputElement).click();
                    }
                  }}
                  type='button'
                  className='py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:text-white dark:hover:bg-neutral-800'
                >
                  <svg
                    className='flex-shrink-0 size-4'
                    xmlns='http://www.w3.org/2000/svg'
                    width={24}
                    height={24}
                    viewBox='0 0 24 24'
                    fill='none'
                    stroke='currentColor'
                    strokeWidth={2}
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  >
                    <path d='M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4' />
                    <polyline points='17 8 12 3 7 8' />
                    <line x1={12} x2={12} y1={3} y2={15} />
                  </svg>

                  {loadingUploadAvatar ? (
                    <ClipLoader color='white' loading size={20} />
                  ) : (
                    'Importer un avatar'
                  )}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default UpdateAvatarForm;
