import { Link } from 'react-router-dom';

const version = require('../../../package.json').version;

const Footer = () => {
  return (
    <footer className='w-full max-w-[85rem] py-10 px-4 sm:px-6 lg:px-8 mx-auto'>
      <div className='text-center'>
        <div>
          <Link
            className='flex-none text-xl font-semibold bg-clip-text bg-gradient-to-tl from-blue-600 to-violet-600 text-transparent'
            to='/'
            aria-label='Mamos crypto'
          >
            Mamos Crypto
          </Link>
        </div>

        <div className='mt-3'>
          <p className='text-gray-500'>Révolutionnez votre trading avec Mamos Crypto</p>

          <p className='text-gray-500'>
            © Mamos Crypto. 2024. All rights reserved. Version {version}
          </p>
        </div>
        {/* Social Brands */}
        <div className='mt-3 space-x-2'>
          <Link
            className='inline-flex justify-center items-center size-10 text-center text-gray-500 hover:bg-gray-100 rounded-full focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 focus:ring-offset-white transition dark:text-gray-500 dark:hover:text-gray-200 dark:hover:bg-gray-800'
            to='https://www.youtube.com/c/CRYPTOMAMOS'
            target='_blank'
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width={20}
              height={20}
              viewBox='0 0 24 24'
              fill='none'
              stroke='currentColor'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
              className='lucide lucide-youtube flex-shrink-0 size-3.5'
            >
              <path d='M2.5 17a24.12 24.12 0 0 1 0-10 2 2 0 0 1 1.4-1.4 49.56 49.56 0 0 1 16.2 0A2 2 0 0 1 21.5 7a24.12 24.12 0 0 1 0 10 2 2 0 0 1-1.4 1.4 49.55 49.55 0 0 1-16.2 0A2 2 0 0 1 2.5 17' />
              <path d='m10 15 5-3-5-3z' />
            </svg>
          </Link>
        </div>
        {/* End Social Brands */}
      </div>
    </footer>
  );
};

export default Footer;
