import { createSlice, createAsyncThunk, ActionReducerMapBuilder } from '@reduxjs/toolkit';

import { schema, NormalizedSchema, normalize } from 'normalizr';

import { callApi } from '../api';
import { CreateUpdateAnalyseProps, Analyse } from '../../types/Analyse';
import { Comment } from '../../types/Comment';

import { filter, findIndex, omit } from 'lodash';

const dataSchema = new schema.Entity('analyses', {}, { idAttribute: 'analyseId' });

type DataNormalized = NormalizedSchema<{ analyses: { [key: string]: Analyse } }, string[]>;

interface State {
  data: DataNormalized['entities']['analyses'];
  status: 'idle' | 'loading' | 'failed';
  totalCount: number;
  totalPages: number;
}
const initialState: State = {
  data: {},
  status: 'idle',
  totalCount: 0,
  totalPages: 0,
};

export const getAnalyses = createAsyncThunk('getAnalyses', async (page: number) => {
  const response = await callApi(`/analyses/page/${page}`, 'GET', null);

  return response;
});

export const getAnalyse = createAsyncThunk('getAnalyse', async (analyseId: number) => {
  const response = await callApi(`/analyses/${analyseId}`, 'GET', null);
  return response;
});

export const createAnalyse = createAsyncThunk(
  'createAnalyse',
  async (data: CreateUpdateAnalyseProps) => {
    const response = await callApi('/analyses', 'POST', null, data);
    return response;
  }
);

export const deleteAnalyse = createAsyncThunk('deleteAnalyse', async (analyse: Analyse) => {
  const response = await callApi(`/analyses/${analyse.analyseId}`, 'DELETE', null, analyse);
  return response;
});

export const updateAnalyse = createAsyncThunk(
  'updateAnalyse',
  async ({ analyseId, analyse }: { analyseId: number; analyse: CreateUpdateAnalyseProps }) => {
    const response = await callApi(
      `/analyses/${analyseId}`,
      'PUT',
      null,
      omit(analyse, ['crypto'])
    );

    return response;
  }
);

export const toogleLikeAnalyse = createAsyncThunk(
  'toogleLikeAnalyse',
  async (analyseId: number) => {
    const response = await callApi(`/analyses/like/${analyseId}`, 'PUT');

    return response;
  }
);

export const toogleLikeComment = createAsyncThunk('toogleLikeComment', async (comment: Comment) => {
  const response = await callApi(`/comments/like/${comment.commentId}`, 'PUT');

  return response;
});

export const commentAnalyse = createAsyncThunk(
  'commentAnalyse',
  async ({ analyseId, text }: { analyseId: number; text: string }) => {
    const response = await callApi(`/comments/analyse/${analyseId}`, 'POST', null, { text });

    return response;
  }
);

export const updateComment = createAsyncThunk(
  'updateComment',
  async ({ comment, text }: { comment: Comment; text: string }) => {
    const response = await callApi(`/comments/${comment.commentId}`, 'PUT', null, { text });
    return response;
  }
);

export const deleteComment = createAsyncThunk('deleteComment', async (comment: Comment) => {
  const response = await callApi(`/comments/${comment.commentId}`, 'DELETE', null, comment);
  return response;
});

const AnalysesSlice = createSlice({
  name: 'analyses',
  initialState,
  reducers: {
    // You can have other non-async actions here
  },
  extraReducers: (builder: ActionReducerMapBuilder<State>) => {
    builder

      /*------------------------------------------------
                          getAnalyse()
      --------------------------------------------------*/
      .addCase(getAnalyse.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getAnalyse.fulfilled, (state, action) => {
        state.status = 'idle';
        const analyseId = action.meta.arg;

        state.data = {
          ...state.data,
          [analyseId]: action.payload,
        };
      })
      .addCase(getAnalyse.rejected, (state, action) => {
        state.status = 'failed';
      })

      /*------------------------------------------------
                          getAnalyses()
      --------------------------------------------------*/
      .addCase(getAnalyses.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getAnalyses.fulfilled, (state, action) => {
        const data = action.payload;

        state.status = 'idle';
        state.data =
          (data &&
            data.paginateData &&
            normalize(data.paginateData, [dataSchema]).entities.analyses) ||
          {};
        state.totalPages = (data && data.totalPages) || 0;
        state.totalCount = (data && data.totalCount) || 0;
      })
      .addCase(getAnalyses.rejected, (state, action) => {
        state.status = 'failed';
      })
      /*------------------------------------------------
                          createAnalyse()
      --------------------------------------------------*/
      .addCase(createAnalyse.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createAnalyse.fulfilled, (state, action) => {
        state.status = 'idle';
        state.data = {
          ...state.data,
          [action.payload.analyseId]: action.payload,
        };
      })
      .addCase(createAnalyse.rejected, (state, action) => {
        state.status = 'failed';
      })
      /* ------------------------------------------------
             commentAnalyse()
--------------------------------------------
 */
      .addCase(commentAnalyse.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(commentAnalyse.fulfilled, (state, action) => {
        state.status = 'idle';
        const updatedData = action.payload;
        state.data[updatedData.analyseId] = updatedData;
      })
      .addCase(commentAnalyse.rejected, (state, action) => {
        state.status = 'failed';
      })
      /* ------------------------------------------------
             updateAnalyse()
--------------------------------------------
 */
      .addCase(updateAnalyse.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateAnalyse.fulfilled, (state, action) => {
        state.status = 'idle';
        const updatedData = action.payload;
        state.data[updatedData.analyseId] = updatedData;
      })
      .addCase(updateAnalyse.rejected, (state, action) => {
        state.status = 'failed';
      })
      /* ------------------------------------------------
             toogleLikeComment()
--------------------------------------------
 */
      .addCase(toogleLikeComment.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(toogleLikeComment.fulfilled, (state, action) => {
        state.status = 'idle';

        const comment = action.payload;

        const indexComment = findIndex(
          state.data[comment.analyseId].comments,
          (c) => c.commentId === comment.commentId
        );

        state.data[comment.analyseId].comments[indexComment] = action.payload;
      })
      .addCase(toogleLikeComment.rejected, (state, action) => {
        state.status = 'failed';
      })

      /* ------------------------------------------------
             updateComment()
--------------------------------------------
 */
      .addCase(updateComment.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateComment.fulfilled, (state, action) => {
        state.status = 'idle';

        const comment = action.payload;

        const indexComment = findIndex(
          state.data[comment.analyseId].comments,
          (c) => c.commentId === comment.commentId
        );

        state.data[comment.analyseId].comments[indexComment] = action.payload;
      })
      .addCase(updateComment.rejected, (state, action) => {
        state.status = 'failed';
      })
      /* ------------------------------------------------
             toogleLikeAnalyse()
--------------------------------------------
 */
      .addCase(toogleLikeAnalyse.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(toogleLikeAnalyse.fulfilled, (state, action) => {
        state.status = 'idle';
        const updatedData = action.payload;
        state.data[updatedData.analyseId] = updatedData;
      })
      .addCase(toogleLikeAnalyse.rejected, (state, action) => {
        state.status = 'failed';
      })
      /* ------------------------------------------------
                    deleteAnalyse()
     ------------------------------------------------- */
      .addCase(deleteAnalyse.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteAnalyse.fulfilled, (state, action) => {
        state.status = 'idle';

        const newData = { ...state.data };
        delete newData[action.payload.analyseId];
        state.data = newData;
      })
      .addCase(deleteAnalyse.rejected, (state, action) => {
        state.status = 'failed';
      })

      /* ------------------------------------------------
                    deleteComment()
     ------------------------------------------------- */
      .addCase(deleteComment.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteComment.fulfilled, (state, action) => {
        state.status = 'idle';

        const comment = action.payload;

        state.data[comment.analyseId].comments = filter(
          state.data[comment.analyseId].comments,
          (c) => c.commentId !== comment.commentId
        );
      })
      .addCase(deleteComment.rejected, (state, action) => {
        state.status = 'failed';
      });
  },
});

export default AnalysesSlice.reducer;
