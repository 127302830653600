import { Fragment } from 'react';

import { Helmet } from 'react-helmet-async';
import DefaultLayout from '../components/layout/DefaultLayout';

import Hero from '../components/home/Hero';
import Features from '../components/home/Features';
import Videos from '../components/home/Videos';
import About from '../components/home/About';

export default function HomePage() {
  return (
    <Fragment>
      <Helmet>
        <title>Mamos Crypto [ Accueil ]</title>
      </Helmet>
      <DefaultLayout>
        <Hero />
        <About />
        <Features />
        <Videos />
      </DefaultLayout>
    </Fragment>
  );
}
