import { Fragment, useState } from 'react';
import { Helmet } from 'react-helmet-async';

import { Link, useNavigate } from 'react-router-dom';

import { LoginProps, User } from '../../types/User';
import { ApiError } from '../../types/Api';

import { AppDispatch } from '../../store/configureStore';
import { useDispatch } from 'react-redux';
import { loginUser } from '../../store/modules/auth';

import { ClipLoader } from 'react-spinners';
import { useForm } from 'react-hook-form';
import { AlertCircle } from 'lucide-react';

import { Button } from '../../components/ui/button';
import { Input } from '../../components/ui/input';
import { Label } from '../../components/ui/label';
import { Alert, AlertDescription, AlertTitle } from '../../components/ui/alert';
import NavigationBar from '../../components/layout/NavigationBar';

const LoginPage = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginProps>();
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const navigate = useNavigate();

  const dispatch = useDispatch<AppDispatch>();

  const onSubmit = (data: LoginProps) => {
    setError(null);
    setLoading(true);
    return dispatch(loginUser(data))
      .unwrap()
      .then((res: User) => {
        setLoading(false);
        if (res) {
          if (res.roles.includes('ADMIN')) {
            return navigate('/admin/analyses');
          }
          if (res.roles.includes('USER')) {
            return navigate('/analyses');
          }
        }
      })
      .catch((e: ApiError) => {
        setLoading(false);
        setError(e.message);
      });
  };

  return (
    <Fragment>
      <Helmet>
        <title>Mamos Crypto [ Connexion ]</title>
      </Helmet>

      <NavigationBar />
      <div className='w-full lg:grid lg:min-h-[600px] lg:grid-cols-2 xl:min-h-[800px]'>
        <div className='flex items-center justify-center py-12'>
          <div className='mx-auto grid w-[350px] gap-6'>
            <div
              onClick={() => navigate(-1)}
              className='text-center inline-flex items-center align-middle text-md text-gray-600 decoration-2 hover:underline dark:text-blue-500'
            >
              <svg
                className='flex-shrink-0 size-4'
                xmlns='http://www.w3.org/2000/svg'
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
                stroke='currentColor'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
              >
                <path d='m15 18-6-6 6-6'></path>
              </svg>
              Retour
            </div>
            <div className='grid gap-2 text-center'>
              <h1 className='text-3xl font-bold'>Connexion</h1>
              <p className='text-balance text-muted-foreground'>
                Entrez votre adresse e-mail ci-dessous pour vous connecter à votre compte.
              </p>
            </div>

            <form className='grid gap-4' onSubmit={handleSubmit(onSubmit)}>
              <div className='grid gap-2'>
                <Label htmlFor='email'>Email *</Label>
                <Input
                  id='email'
                  type='email'
                  placeholder='m@example.com'
                  {...register('email', {
                    required: 'Adresse email requise',
                    pattern: {
                      value: /^\S+@\S+$/i,
                      message: 'Adresse email invalide',
                    },
                  })}
                  required
                />
                {errors && errors.email && errors.email.message && <p>{errors.email.message}</p>}
              </div>
              <div className='grid gap-2'>
                <div className='flex items-center'>
                  <Label htmlFor='password'>Mot de passe *</Label>
                  <Link to='/auth/password' className='ml-auto inline-block text-sm underline'>
                    Mot de passe oublié
                  </Link>
                </div>
                <Input
                  {...register('password', {
                    required: "Nom d'utilisateur requis",
                    minLength: {
                      value: 6,
                      message: 'Le mot de passe doit contenir au moins 6 caractères',
                    },
                  })}
                  id='password'
                  type='password'
                  required
                />
                {errors && errors.password && errors.password.message && (
                  <p>{errors.password.message}</p>
                )}
              </div>
              {error && (
                <Alert variant='destructive'>
                  <AlertCircle className='h-4 w-4' />
                  <AlertTitle>Erreur</AlertTitle>
                  <AlertDescription>{error}</AlertDescription>
                </Alert>
              )}
              <Button type='submit' className='w-full' disabled={loading}>
                {loading ? <ClipLoader color='white' loading size={20} /> : 'Connexion'}
              </Button>
            </form>
            <div className='mt-4 text-center text-sm'>
              Pas encore de compte ?{' '}
              <Link to='/auth/register' className='underline'>
                Créer un compte
              </Link>
            </div>
          </div>
        </div>
        <div className='hidden bg-muted lg:block'>
          <img
            src='/jarvis.jpg'
            alt='Jarvis login'
            width='1920'
            height='1080'
            className='h-full w-full object-cover'
          />
        </div>
      </div>
    </Fragment>
  );
};

export default LoginPage;
