import { useEffect, useState } from "react";

import { Comment } from "../../types/Comment";

import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store/configureStore";
import { deleteComment, getComments } from "../../store/modules/comments";

import { map, orderBy } from "lodash";
import moment from "moment";

import { Edit, Eye, Trash } from "lucide-react";

import { Button } from "../../components/ui/button";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../../components/ui/card";
import { DialogTrigger } from "../../components/ui/dialog";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components/ui/table";
import { Tabs, TabsContent } from "../../components/ui/tabs";
import AdminLayout from "../../components/layout/AdminLayout";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "../../components/ui/alert-dialog";

import ModalEditComment from "../../components/comments/ModalEditComment";
import Pagination from "../../components/Pagination";

const CommentairesPageAD = () => {
  const dispatch = useDispatch<AppDispatch>();

  const comments = useSelector((state: RootState) => state.comments.data);

  const [openModalEdit, setOpenModalEdit] = useState<boolean>(false);
  const [loadingDelete, setLoadingDelete] = useState<boolean>(false);
  const [commentSelected, setCommentSelected] = useState<Comment | null>(null);

  const countComments = useSelector(
    (state: RootState) => state.comments.totalCount
  );

  const [actualPage, setActualPage] = useState<number>(1);
  const maxPages = useSelector((state: RootState) => state.comments.totalPages);

  useEffect(() => {
    dispatch(getComments(actualPage));
  }, [actualPage]);

  const actionDelete = () => {
    setLoadingDelete(true);

    if (commentSelected) {
      return dispatch(deleteComment(commentSelected))
        .unwrap()
        .then(() => {
          setLoadingDelete(false);
          setCommentSelected(null);
        })
        .catch(() => {
          setLoadingDelete(false);
        });
    }
  };

  return (
    <AdminLayout>
      <AlertDialog>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Supprimer ce commentaire</AlertDialogTitle>
            <AlertDialogDescription>
              Confirmer vous la suppresion de ce commentaire ?
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel
              onClick={() => {
                setCommentSelected(null);
              }}
            >
              Annuler
            </AlertDialogCancel>
            <AlertDialogAction
              disabled={loadingDelete}
              onClick={() => actionDelete()}
            >
              Oui
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>

        <div className="flex min-h-screen w-full flex-col">
          <div className="flex flex-col sm:gap-4 sm:py-4 sm:pl-14">
            <main className="grid flex-1 items-start gap-4 p-4 sm:px-6 sm:py-0 md:gap-8">
              <Tabs defaultValue="all">
                <TabsContent value="all">
                  <Card>
                    <CardHeader>
                      <CardTitle>Commentaires</CardTitle>
                    </CardHeader>

                    <CardContent>
                      <Table>
                        <TableHeader>
                          <TableRow>
                            <TableHead>Pseudo</TableHead>
                            <TableHead>Contenu</TableHead>
                            <TableHead>Likes</TableHead>
                            <TableHead className="hidden md:table-cell">
                              Création
                            </TableHead>
                            <TableHead>
                              <span className="sr-only">Actions</span>
                            </TableHead>
                          </TableRow>
                        </TableHeader>
                        <TableBody>
                          {map(
                            orderBy(comments, ["createdAt"], ["desc"]),
                            (c) => (
                              <TableRow key={c.commentId}>
                                <TableCell className="font-medium">
                                  {(c.user && c.user.pseudo) || ""}
                                </TableCell>
                                <TableCell className="font-medium">
                                  {c.content}
                                </TableCell>

                                <TableCell className="font-medium">
                                  {(c.likes && c.likes.length) || "0"}
                                </TableCell>
                                <TableCell className="hidden md:table-cell">
                                  {moment(c.createdAt).format(
                                    "DD/MM/YYYY HH:mm"
                                  )}
                                </TableCell>

                                <TableCell>
                                  <div className="hidden items-center gap-3 sm:ml-auto sm:flex">
                                    <Button
                                      variant="outline"
                                      size="icon"
                                      onClick={() => {
                                        window.open(
                                          `/analyse/${c.analyseId}`,
                                          "_blank"
                                        );
                                      }}
                                    >
                                      <Eye className="h-4 w-4" />
                                      <span className="sr-only">Voir</span>
                                    </Button>
                                    <ModalEditComment
                                      openModal={openModalEdit}
                                      closeModal={() => {
                                        setCommentSelected(null);
                                        setOpenModalEdit(false);
                                      }}
                                      commentSelected={commentSelected}
                                      dialogTrigger={
                                        <DialogTrigger asChild>
                                          <Button
                                            variant="default"
                                            size="icon"
                                            onClick={() => {
                                              setOpenModalEdit(true);
                                              setCommentSelected(c);
                                            }}
                                          >
                                            <Edit className="h-4 w-4" />
                                            <span className="sr-only">
                                              Modifier
                                            </span>
                                          </Button>
                                        </DialogTrigger>
                                      }
                                    />

                                    <AlertDialogTrigger asChild>
                                      <Button
                                        variant="destructive"
                                        size="icon"
                                        onClick={() => {
                                          setCommentSelected(c);
                                        }}
                                      >
                                        <Trash className="h-4 w-4" />
                                        <span className="sr-only">
                                          Supprimer
                                        </span>
                                      </Button>
                                    </AlertDialogTrigger>
                                  </div>
                                </TableCell>
                              </TableRow>
                            )
                          )}
                        </TableBody>
                      </Table>
                    </CardContent>
                    <Pagination
                      pageSelected={actualPage}
                      pageMax={maxPages}
                      onChangePage={(p) => setActualPage(p)}
                    />
                    <CardFooter>
                      <div className="text-xs text-muted-foreground">
                        <strong>{countComments || 0}</strong> commentaires
                      </div>
                    </CardFooter>
                  </Card>
                </TabsContent>
              </Tabs>
            </main>
          </div>
        </div>
      </AlertDialog>
    </AdminLayout>
  );
};
export default CommentairesPageAD;
